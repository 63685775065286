<template>
  <div id="app" class="relative">
    <div v-if="authenticated">
      <loader ref="loader"></loader>
      <header-component v-if="loaded"></header-component>
      <div v-if="loaded" class="main-content">
        <router-view></router-view>
      </div>
      <footer-component v-if="loaded"></footer-component>
    </div>
    <div v-else class="login-form-cont center vertical layout align-center">
      <img src="./assets/images/logo.jpg" alt="The Grid" class="w-100">
      <div class="tuple w-100">
        <p class="ft-size-16 ft-neutral-black">Email</p>
        <input type="text" class="w-100" v-model="email" spellcheck="false">
      </div>
      <div class="tuple w-100">
        <p class="ft-size-16 ft-neutral-black">Password</p>
        <input type="password" class="w-100" v-model="password" spellcheck="false">
      </div>
      <button class="primary ft-size-18 ft-w-bold d-block shadow w-100" v-on:click="login">LOGIN</button>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import Loader from "./views/Loader.vue";
  import HeaderComponent from "./components/Shared/HeaderComponent.vue";
  import FooterComponent from "./components/Shared/FooterComponent.vue";

  Vue.component("loader",Loader);
  Vue.component("header-component",HeaderComponent);
  Vue.component("footer-component",FooterComponent);

  export default {
    name: "app",
    components: {
      Loader,
      HeaderComponent,
      FooterComponent
    },
    created() {
      this.setPageTitle();
    },
    mounted() {
      this.setLangAttrs();
      if(this.authenticated)
        this.startLoading();
    },
    watch: {
      $route: function() {
        this.setPageTitle();
        window.scroll(0,0);
      }
    },
    computed: {
      authenticated: function() {
        return localStorage.getItem("authenticated");
      }
    },
    data() {
      return {
        loaded: false,
        email: "",
        password: ""
      }
    },
    methods: {
      setLangAttrs: function() {
        let lang = localStorage.getItem("lang");
        let direction = "";
        if(lang == "en")
          direction = "ltr";
        else if(lang == "ar")
          direction = "rtl";
        document.querySelector("body").setAttribute("dir",direction);
        document.querySelector("body").style.direction = direction;
      },
      setPageTitle: function() {
        if(this.$route.meta.pageTitle)
          document.title = "THE GRID | " + this.$route.meta.pageTitle;
      },
      startLoading: function() {
        setTimeout(() => {
          this.$refs.loader.loaded();
          this.loaded = true;
        },3000);
      },
      login: function() {
        if(this.email == "firas-al-kassir@hotmail.com" && this.password == "0798970850"){
          localStorage.setItem("authenticated","firas");
          window.location.reload();
        }else{
          this.email = "";
          this.password = "";
        }
      }
    }
  };
</script>

<style lang="scss">
  #app{
    min-height: 100vh;
    padding-top: 80px;
    .main-content{
      min-height: calc(100vh - 467px);
    }
    .login-form-cont{
      max-width: 300px;
      img{
        margin: 10px 0 25px 0;
        filter: invert(1);
      }
      .tuple{
        margin-bottom: 16px;
        p{
          margin: 0 0 6px 0;
        }
        input{
          padding: 8px;
          border-radius: 4px;
        }
      }
      button{
        margin: 15px 0 50px 0;
        padding: 6px 0;
      }
    }
  }

  @media only screen and (max-width: 575px){
    #app{
      .login-form-cont{
        img{
          width: 200px;
        }
      }
    }
  }
</style>
