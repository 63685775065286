<template>
  <div class="trainers-network-cont">
    <div class="hero-cont vertical layout align-center justify-center relative">
      <img src="../../assets/images/trainers-network.jpg" alt="trainers network" class="w-100 h-100 absolute rellax" data-rellax-speed="-3">
      <div class="relative w-100">
        <p class="title ft-size-70 ft-neutral-white ft-w-bold text-center center">{{$t("trainings.trainers-network")}}</p>
      </div>
    </div>
    <div class="wrapper">
      <div class="trainers-cont horizontal layout wrap justify-center">
        <trainer-card v-for="(trainer,index) in trainers" v-bind:key="index" v-bind:trainer="trainer"></trainer-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import Rellax from "rellax";
  import TrainerCard from "../../components/TrainingsMentorship/TrainerCard.vue";

  Vue.component("trainer-card",TrainerCard);

  export default {
    name: "trainers-network",
    components: {
      TrainerCard
    },
    mounted() {
      new Rellax('.rellax');
    },
    data() {
      return {
        trainers: [
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          }
        ]
      }
    },
    methods: {
      
    }
  };
</script>

<style scoped lang="scss">
  .trainers-network-cont{
    .hero-cont{
      height: 400px;
      overflow: hidden;
      background-color: #d8d8d8;
      img{
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
        opacity: 0.7;
      }
      .title{
        text-shadow: 0 0px 8px rgba(0,0,0,0.5);
        max-width: 750px;
      }
    }
    .wrapper{
      padding: 50px 0;
    }
  }

  @media only screen and (max-width: 768px){
    .trainers-network-cont{
      .hero-cont{
        height: 400px;
        .title{
          font-size: 40px;
          max-width: 90%;
          margin-top: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .trainers-network-cont{
   
    }
  }
</style>