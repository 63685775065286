<template>
  <div class="freelancer-card-cont shadow border-radius-4">
    <div class="bio-cont">
      <div class="horizontal layout">
        <img v-bind:src="freelancer.pp" v-bind:alt="freelancer.name" class="border-radius-full">
        <div class="vertical layout">
          <p class="name ft-size-18 ft-grey ft-w-bold">{{freelancer.name}} <a v-bind:href="freelancer.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a></p>
          <p class="ft-size-16 ft-grey m-0">{{freelancer.title}}</p>
        </div>
      </div>
      <div class="horizontal layout align-end">
        <p class="rate ft-size-16 ft-grey"><span class="ft-primary ft-w-bold">${{freelancer.hourly_rate}}</span> /{{$t("shared.hour")}}</p>
        <p class="ft-size-16 ft-grey m-0">{{freelancer.country}}</p>
      </div>
    </div>
    <hr/>
    <div class="tags-cont horizontal layout wrap">
      <div class="tag bg-light-grey border-radius-6" v-for="(tag,index) in freelancer.tags" v-bind:key="index">
        <p class="ft-size-14 ft-grey m-0">{{tag}}</p>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "freelancer-card",
    mounted() {

    },
    props: {
      freelancer: {}
    },
    data() {
      return {

      }
    },
    methods: {

    }
  };  
</script>

<style scoped lang="scss">
  .freelancer-card-cont{
    padding: 18px 12px;
    margin: 0 16px 24px 16px;
    max-width: 300px;
    .bio-cont{
      img{
        width: 125px;
        height: 125px;
      }
      p{
        line-height: 1.25;
        &.name{
          margin: 0 0 8px 0;
          i{
            color: #2e66c2;
          }
        }
        &.rate{
          width: 141px;
          margin: 12px 0 0 0;
          padding: 0 8px;
        }
      }
    }
    hr{
      margin: 12px 0;
      border: 0;
      border-top: 1px solid #dcdcdc;
    }
    .tags-cont{
      .tag{
        padding: 8px 12px;
      }
    }
  }

  body[dir="ltr"] {
    .freelancer-card-cont{
      .bio-cont{
        img{
          margin-right: 16px;
        }
      }
      .tags-cont{
        .tag{
          margin: 0 10px 8px 0;
        }
      }
    }
  }

  body[dir="rtl"] {
    .freelancer-card-cont{
      .bio-cont{
        img{
          margin-left: 16px;
        }
      }
      .tags-cont{
        .tag{
          margin: 0 0 8px 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .freelancer-card-cont{

    }
  }
</style>