<template>
  <div class="header-component-cont bg-neutral-black w-100 fixed shadow">
    <div class="wrapper w-100 h-100 horizontal layout space-between">
      <img src="../../assets/images/logo.jpg" alt="logo" class="logo pointer" v-on:click="$router.push({path:'/home'}).catch(error => {})">
      <div class="user-actions-cont horizontal layout align-center">
        <button class="secondary ft-size-16 ft-w-bold">{{$t("shared.sign-up")}}</button>
        <button class="primary ft-size-16 ft-w-bold">{{$t("shared.login")}}</button>
        <el-dropdown trigger="click" @command="handleCommand">
          <span class="el-dropdown-link">
            <i class="ft-size-30 ft-neutral-white fas fa-globe pointer"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="en" class="force-roboto" v-bind:class="{selected: langSelected('en')}">English</el-dropdown-item>
            <el-dropdown-item command="ar" class="force-cairo" v-bind:class="{selected: langSelected('ar')}">العربية</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import {Dropdown,DropdownMenu,DropdownItem} from "element-ui";

  Vue.component("el-dropdown",Dropdown);
  Vue.component("el-dropdown-menu",DropdownMenu);
  Vue.component("el-dropdown-item",DropdownItem);

  export default {
    name: "header-component",
    components: {
      Dropdown,
      DropdownMenu,
      DropdownItem
    },
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      handleCommand(command) {
        let currentLang = localStorage.getItem("lang");
        if(command != currentLang){
          localStorage.setItem("lang",command);
          window.location.reload();
        }
      },
      langSelected: function(lang) {
        if(localStorage.getItem("lang") == lang)
          return true;

        return false;
      }
    }
  };  
</script>

<style scoped lang="scss">
  .header-component-cont{
    height: 80px;
    top: 0;
    padding: 8px 0;
    z-index: 9999;
    .wrapper{
      .logo{
        height: 100%;
        width: auto;
      }
      .user-actions-cont{
        button{
          width: 100px;
          height: 40px;
        }
      }
    }
  }

  body[dir="ltr"] {
    .header-component-cont{
      .wrapper{
        .user-actions-cont{
          button{
            margin-left: 12px;
          }
          i{
            margin-left: 12px;
          }
        }
      }
    }
  }

  body[dir="rtl"] {
    .header-component-cont{
      .wrapper{
        .user-actions-cont{
          button{
            margin-right: 12px;
          }
          i{
            margin-right: 12px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .header-component-cont{

    }
  }

  @media only screen and (max-width: 350px){
    .header-component-cont{
      .wrapper{
        .user-actions-cont{
          button{
            width: 80px;
          }
        }
      }
    }
  }
</style>