<template>
  <div class="home-cont">
    <div class="hero-cont horizonal layout">
      <div class="flex pointer align-center d-flex transition-300 relative" v-on:click="$router.push({path:'/coworking-spaces'}).catch(error => {})">
        <p class="ft-size-50 ft-neutral-white ft-w-bold text-center center rellax">{{$t("shared.coworking-spaces")}}</p>
      </div>
      <div class="flex pointer align-center d-flex transition-300 relative" v-on:click="$router.push({path:'/business-incubators'}).catch(error => {})">
        <p class="ft-size-50 ft-neutral-white ft-w-bold text-center center rellax">{{$t("shared.business-incubators")}}</p>
      </div>
      <div class="flex pointer align-center d-flex transition-300 relative" v-on:click="$router.push({path:'/trainings-and-mentorship'}).catch(error => {})">
        <p class="ft-size-50 ft-neutral-white ft-w-bold text-center center rellax">{{$t("shared.training-mentorship")}}</p>
      </div>
    </div>
    <div class="about-us-cont bg-nuetral-white">
      <div class="wrapper horizontal layout align-center">
        <div class="content">
          <p class="subtitle ft-size-18 ft-primary ft-w-bold m-0">{{$t("home.about-us.subtitle")}}</p>
          <p class="title ft-grey ft-size-40 ft-w-bold">{{$t("home.about-us.title")}}</p>
          <p class="desc ft-grey ft-size-16 m-0">{{$t("home.about-us.desc")}}</p>
        </div>
        <span class="flex"></span>
        <img src="../assets/images/home/about-us.jpg" alt="about us">
      </div>
    </div>
    <div class="video-cont relative">
      <p class="ft-primary ft-size-40 ft-w-bold text-center">{{$t("home.video-title")}}</p>
      <video playsinline autoplay muted loop>
        <source src="../assets/videos/pursue-your-dreams.mp4" type="video/mp4">
      </video>
    </div>
    <div class="how-cont bg-nuetral-white">
      <div class="wrapper horizontal layout align-center">
        <img src="../assets/images/home/help.jpg" alt="help">
        <span class="flex"></span>
        <div class="content">
          <p class="subtitle ft-size-18 ft-primary ft-w-bold m-0">{{$t("home.how.subtitle")}}</p>
          <p class="title ft-grey ft-size-40 ft-w-bold">{{$t("home.how.title")}}</p>
          <p class="desc ft-grey ft-size-16 m-0">{{$t("home.how.desc")}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Rellax from "rellax";

  export default {
    name: "home",
    mounted() {
      new Rellax('.rellax');
    },
    data() {
      return {

      }
    },
    methods: {

    }
  };
</script>

<style scoped lang="scss">
  .home-cont{
    .hero-cont{
      min-height: 500px;
      overflow: hidden;
      &>div{
        overflow: hidden;
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: #00000026;
        background-blend-mode: color;
        p{
          text-shadow: 0 0px 8px rgba(0,0,0,0.5);
          max-width: 365px;
        }
        &:hover,&:focus,&:active{
          -ms-transform: scale(1.2);
          -moz-transform: scale(1.2);
          -webkit-transform: scale(1.2);
          -o-transform: scale(1.2);
          transform: scale(1.2);
        }
      }
      &>div:nth-of-type(1){
        background-image: url("../assets/images/services/coworking.jpg");
      }
      &>div:nth-of-type(2){
        background-image: url("../assets/images/services/business-incubators.jpg");
        z-index: 9;
      }
      &>div:nth-of-type(3){
        background-image: url("../assets/images/services/trainings-mentorship.jpg");
      }
    }
    .about-us-cont,.how-cont{
      .wrapper{
        padding-top: 50px;
        padding-bottom: 50px;
        .content{
          max-width: calc(70% - 50px);
          .title{
            margin: 10px 0 34px 0;
          }
          .desc{
            line-height: 32px;
          }
        }
        img{
          width: 30%;
          height: auto;
        }
      }
    }
    .video-cont{
      padding: 24px 0;
      p{
        margin: 0 0 24px 0;
      }
      video{
        margin: 0 auto;
        display: block;
        width: 100%;
        max-height: 500px;
        object-fit: cover;
      }
    }
  }

  @media only screen and (max-width: 1110px){
    .home-cont{
      .hero-cont{
        height: 285px;
        min-height: unset;
        &>div{
          p{
            font-size: 32px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .home-cont{
      .hero-cont{
        height: 450px;
        min-height: unset;
        flex-direction: column;
        &>div{
          p{
            transform: none!important;
          }
        }
      }
      .about-us-cont,.how-cont{
        .wrapper{
          padding-top: 30px;
          padding-bottom: 30px;
          flex-direction: column;
          .content{
            max-width: unset;
            .subtitle{
              font-size: 16px;
            }
            .title{
              font-size: 32px;
              margin: 10px 0 18px 0;
            }
            .desc{
              line-height: 24px;
            }
          }
          img{
            width: 100%;
            margin-top: 24px;
          }
        }
      }
      .how-cont{
        .wrapper{
          flex-direction: column-reverse;
        }
      }
      .video-cont{
        p{
          font-size: 28px;
          margin: 0 0 14px 0;
        }
      }
    }
  }
</style>