<template>
  <div class="footer-component-cont bg-dark-grey w-100">
    <div class="wrapper w-100 h-100">
      <div class="horizontal layout space-between">
        <div class="info-cont flex">
          <img src="../../assets/images/logo-transparent.png" alt="logo" class="logo">
          <p class="ft-size-14 ft-neutral-white ft-w-bold">Amman,Jordan</p>
          <p class="ft-size-14 ft-neutral-white ft-w-bold">firas-al-kassir@hotmail.com</p>
          <p class="ft-size-14 ft-neutral-white ft-w-bold force-ltr">+962798970850</p>
        </div>
        <div class="flex">
          <p class="title ft-size-18 ft-secondary ft-w-bold">{{$t("shared.our-services")}}</p>
          <p class="link ft-size-16 ft-neutral-white pointer width-fit" v-on:click="$router.push({path:'/coworking-spaces'}).catch(error => {})">{{$t("shared.coworking-spaces")}}</p>
          <p class="link ft-size-16 ft-neutral-white pointer width-fit" v-on:click="$router.push({path:'/business-incubators'}).catch(error => {})">{{$t("shared.business-incubators")}}</p>
          <p class="link ft-size-16 ft-neutral-white pointer width-fit" v-on:click="$router.push({path:'/trainings-and-mentorship'}).catch(error => {})">{{$t("shared.training-mentorship")}}</p>
        </div>
        <div class="flex">
          <p class="title ft-size-18 ft-secondary ft-w-bold">{{$t("shared.useful-links")}}</p>
          <p class="link ft-size-16 ft-neutral-white pointer width-fit">{{$t("shared.about")}}</p>
          <p class="link ft-size-16 ft-neutral-white pointer width-fit">{{$t("shared.contact-us")}}</p>
          <p class="link ft-size-16 ft-neutral-white pointer width-fit">{{$t("shared.terms-conditions")}}</p>
          <p class="link ft-size-16 ft-neutral-white pointer width-fit">{{$t("shared.privacy-policy")}}</p>
        </div>
      </div>
      <hr/>
      <p class="copyrights ft-size-14 ft-neutral-white ft-w-bold m-0 force-ltr">©THE GRID {{currentYear}}</p>
    </div>
  </div>
</template> 

<script>
  export default {
    name: "footer-component",
    mounted() {

    },
    computed: {
      currentYear: function() {
        return new Date().getFullYear();
      }
    },
    data() {
      return {

      }
    },
    methods: {

    }
  };  
</script>

<style scoped lang="scss">
  .footer-component-cont{
    padding: 32px 0;
    .wrapper{
      .info-cont{
        img{
          width: 175px;
          margin-bottom: 24px;
        }
        p{
          font-family: monospace;
          margin: 6px 0 0 0;
        }
      }
      .title{
        margin: 24px 0;
      }
      .link{
        margin: 0 0 8px 0;
        &:hover{
          text-decoration: underline;
        }
      }
      hr{
        border: 0;
        border-top: 1px solid #FFF;
        margin: 30px 0 12px 0;
      }
      .copyrights{
        font-family: monospace;
      }
    }
  }

  body[dir="ltr"] {
    .footer-component-cont{
      .wrapper{
        .copyrights{
          text-align: right;
        }
      }
    }
  }

  body[dir="rtl"] {
    .footer-component-cont{
      .wrapper{
        .info-cont{
          text-align: right;
        }
        .copyrights{
          text-align: left;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .footer-component-cont{
      padding: 16px 0;
      .wrapper{
        &>div{
          flex-direction: column-reverse;
        }
        p{
          width: auto;
          text-align: center;
        }
        .info-cont{
          img{
            margin: 24px auto;
            display: block;
            width: 100px;
          }
        }
      }
    }
  }
</style>