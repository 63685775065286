<template>
  <div class="service-card-cont h-100 w-100 relative" v-bind:style="{'background-image': 'URL(' + service.image + ')'}">
    <div class="content horizontal layout space-between absolute w-100 align-end">
      <div class="vertical layout flex">
        <p class="title ft-size-20 ft-primary ft-w-bold">{{service.name}}</p>
        <p class="desc ft-size-16 ft-grey m-0">{{service.desc}}</p>
      </div>
      <button class="primary ft-size-18 ft-w-bold border-radius-32 d-block shadow height-fit" v-on:click="goToServicePage">{{$t("shared.read-more")}}</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "service-card",
    mounted() {
      
    },
    props: {
      service: null
    },
    data() {
      return {

      }
    },
    methods: {
      goToServicePage: function() {
        let path = "/business-incubators/services/" + this.service.url;
        this.$router.push({path: path});
      }
    }
  };  
</script>

<style scoped lang="scss">
  .service-card-cont{
    background-position: center center;
    background-size: cover;
    .content{
      padding: 18px;
      bottom: 0;
      background-color: #ffffffe6;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      &>div{
        max-width: 400px;
      }
      .title{
        margin: 0 0 12px 0;
      }
      .desc{
        line-height: 22px;
      }
      button{
        padding: 8px 20px;
      }
    }
  }

  @media only screen and (max-width: 768px){
    .service-card-cont{
      .content{
        padding: 10px;
        flex-direction: column;
        align-items: center;
        .title{
          margin: 0 0 8px 0;
          font-size: 18px;
        }
        .desc{
          font-size: 12px;
          line-height: 20px;
        }
        button{
          font-size: 14px;
          padding: 6px 12px;
          margin-top: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .service-card-cont{

    }
  }
</style>