<template>
  <div class="space-card-cont w-100 border-radius-2 shadow horizontal layout" v-bind:class="{premium: space.premium}">
    <img v-bind:src="space.pp" v-bind:alt="space.name">
    <div class="space-info flex vertical layout">
      <div class="horizontal layout">
        <p class="name ft-size-26 ft-grey ft-w-bold">{{space.name}}</p>
        <span v-if="space.premium" class="premium ft-size-18 ft-primary ft-w-bold border-radius-6 relative">{{$t("coworking.card.premium")}}</span>
      </div>
      <p class="desc ft-size-18 ft-grey">{{space.desc}}</p>
      <span class="flex"></span>
      <div class="horizontal layout">
        <span class="flex"></span>
        <button class="primary ft-size-16 ft-w-bold shadow width-fit" v-on:click="goToSpacePage">{{$t("shared.view")}}</button>
      </div>
    </div>
    <div class="space-specs vertical layout justify-center align-center">
      <div class="progress-cont">
        <el-progress type="dashboard" v-bind:percentage="percentage" color="#67c23a" v-bind:format="format"></el-progress>
      </div>
      <div class="tags-cont">
        <div v-for="(tag,index) in space.tags" v-bind:key="index" class="tag horizontal layout align-center">
          <i v-bind:class="tag.icon" class="ft-size-14 ft-grey ft-w-bold"></i>
          <p class="ft-size-14 ft-grey ft-w-bold">{{tagTitle(index)}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import {Progress} from "element-ui";
  
  Vue.component("el-progress",Progress);
  
  export default {
    name: "space-card",
    components: {
      Progress
    },
    mounted() {
      
    },
    props: {
      space: null
    },
    computed: {
      percentage: function() {
        let percentage = 0;
        percentage = (parseFloat(this.space.rate) / 5) * 100;
        
        return percentage;
      }
    },
    data() {
      return {

      }
    },
    methods: {
      format: function() {
        return this.space.rate;
      },
      tagTitle: function(index) {
        return this.$t(this.space.tags[index].title);
      },
      goToSpacePage: function() {
        let path = "/coworking-spaces/" + this.space.url;
        this.$router.push({path: path});
      }
    }
  };
</script>

<style scoped lang="scss">
  .space-card-cont{
    height: 250px;
    margin: 40px 0;
    &>img{
      width: 350px;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
    .space-info{
      padding: 24px;
      .name{
        text-transform: uppercase;
        margin: 4px 0 0 0;
      }
      .premium{
        padding: 7px 10px;
        border: 1px solid var(--primary);
      }
      .desc{
        line-height: 24px;
      }
      button{
        padding: 8px 26px;
      }
    }
    .space-specs{
      width: 180px;
      padding: 0 16px;
      margin: 24px 0;
      .tags-cont{
        i{
          margin: -2px 8px 0 8px;
        }
        p{
          margin: 8px 0;
        }
      }
    }
    &.premium{
      border: 1px solid var(--primary);
    }
  }

  body[dir="ltr"] {
    .space-card-cont{
      &:not(.premium){
        &>img{
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
      }
      .space-info{
        .premium{
          margin-left: 16px;
        }
      }
      .space-specs{
        border-left: 1px solid #f1f1f1;
      }
    }
  }

  body[dir="rtl"] {
    .space-card-cont{
      &:not(.premium){
        &>img{
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
      }
      .space-info{
        .premium{
          margin-right: 16px;
        }
      }
      .space-specs{
        border-right: 1px solid #f1f1f1;
      }
    }
  }

  @media only screen and (max-width: 1040px){
    .space-card-cont{
      flex-direction: column;
      height: unset;
      img{
        width: 100%;
        max-height: 250px;
        border-radius: 0!important;
      }
      &:not(.premium) > img{
        border-top-right-radius: 2px!important;
        border-top-left-radius: 2px!important;
      }
      .space-info{
        padding: 20px 16px;
        .name{
          font-size: 18px;
        }
        .premium{
          font-size: 14px;
          padding: 5px 8px;
        }
        .desc{
          font-size: 16px;
          line-height: 22px;
        }
        span{
          display: none;
        }
        button{
          font-size: 14px;
          padding: 8px 70px;
          margin: 0 auto;
        }
      }
      .space-specs{
        border: none!important;
        width: calc(100% - 20px);
        flex-direction: row;
        margin: 0 auto;
        padding: 10px 16px;
        border-top: 1px solid #f1f1f1!important;
        .tags-cont{
          margin: 0 14px;
          p,i{
            font-size: 12px;
          }
        }
      }
    }
  }
</style>