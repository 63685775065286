const base_url = "";

export default {
	data() {
		return {
			DBspaces: [
				{
					name: "Almond Coffee House",
					pp: "https://media-cdn.tripadvisor.com/media/photo-s/14/08/05/b4/unique-light-scheme-in.jpg",
					premium: true,
					desc: "A local coffee house in Amman where you can enjoy your locally roasted premium coffee, work and chill in addition to our fresh goodies. Baked in-house everymorning! ",
					rate: "4.8",
					url: "almond-coffee-house",
					tags: [
						{
							title: "coworking.card.verified",
							icon: "fas fa-check"
						},
						{
							title: "coworking.card.shared-seats",
							icon: "fas fa-chair"
						},
						{
							title: "coworking.card.private-rooms",
							icon: "fas fa-lock"
						}
					],
					address: "ZaAl-Abu Tayee St., Amman",
					images: [
						"https://mir-s3-cdn-cf.behance.net/project_modules/2800_opt_1/7de50668321581.5b58b5eee6656.jpg",
						"https://media-cdn.tripadvisor.com/media/photo-s/14/08/05/b4/unique-light-scheme-in.jpg",
						"https://i.pinimg.com/originals/bc/e8/c4/bce8c470c134937ca7b0d75a01785495.jpg"
					],
					workinghours: [
						{
							day: "Sunday",
							time: "9:00-23:00"
						},
						{
							day: "Monday",
							time: "9:00-23:00"
						},
						{
							day: "Tuesday",
							time: "9:00-23:00"
						},
						{
							day: "Wednesday",
							time: "9:00-23:00"
						},
						{
							day: "Thursday",
							time: "9:00-23:00"
						},
						{
							day: "Friday",
							time: "9:00-23:00"
						},
						{
							day: "Saturday",
							time: "9:00-23:00"
						}
					],
					urls: {
						website: "http://www.almondcoffeehouse.com",
						facebook: "https://web.facebook.com/AlmondCoffeeHouse",
						instagram: "https://www.instagram.com/almondcoffeehouse"
					},
					amenities: [
						{
							title: "ACCESSIBILITY",
							items: [
								"Parking",
								"Public transport nearby",
								"Wheelchair accessible"
							]
						},
						{
							title: "FACILITIES",
							items: [
								"Air condition",
								"English Speaking Staff"
							]
						},
						{
							title: "FOOD & BEVERAGE",
							items: [
								"On-site bar"
							]
						},
						{
							title: "EQUIPMENTS",
							items: [
								"Ergo chairs",
								"Monitors",
								"Printer",
								"Chillout room"
							]
						}
					],
					spaces: [
						{
							name: "Shared Desk",
							type: "Shared Desk",
							count: "20"
						},
						{
							name: "Private Desk",
							type: "Private Desk",
							count: "8"
						},
						{
							name: "Private Room",
							type: "Private Room",
							count: "2"
						},
					],
					lat: 31.977301713665746,
					lng: 35.85438443695824
				},
				{
					name: "Espresso Lab",
					pp: "https://www.espressolab.com/Upload/Sube/Buyuk/352021-amman-2-urdun-2--1102-x-700-131556.jpg",
					premium: true,
					desc: "A specialty coffee house serving a wide variety of gourmet coffee, sandwiches and internationally-renowned sweet delights.",
					rate: "4.4",
					url: "espresso-lab",
					tags: [
						{
							title: "coworking.card.verified",
							icon: "fas fa-check"
						},
						{
							title: "coworking.card.shared-seats",
							icon: "fas fa-chair"
						}
					],
					reviews: [
						{
							reviewer: "Mark Bell",
							pp: "https://www.mantruckandbus.com/fileadmin/media/bilder/02_19/219_05_busbusiness_interviewHeader_1485x1254.jpg",
							date: "06 Jun 2021",
							review: "Espresso lab is one of my favorites places to work at and have business meetings, good vibes and good drinks! highly recommended",
							rate: "5.0"
						},
						{
							reviewer: "Karen Ash",
							pp: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFnohiEoxQeKMo_jx1CKr-Op3mkVRKgAuKRA&usqp=CAU",
							date: "24 May 2021",
							review: "Espresso lab is one of my favorites places to work at and have business meetings, good vibes and good drinks! highly recommended, Espresso lab is one of my favorites places to work at and have business meetings, good vibes and good drinks! highly recommended",
							rate: "4.5"
						}
					],
					address: "Prs. Tharwat St. 67, Amman",
					images: [
						"https://www.espressolab.com/Upload/Sube/Buyuk/352021-amman-2-urdun-2--1102-x-700-131556.jpg",
						"https://www.espressolab.com/Upload/Sube/Buyuk/352021-amman-2-urdun---1102-x-700-131556.jpg",
						"https://fastly.4sqi.net/img/general/600x600/506030046_zxxAqN7hJ2RZOkUWshPi2NeNTSOwMUz3toK6sfyKpnA.jpg",
						"https://i.redd.it/qderajc8nz741.jpg"
					],
					workinghours: [
						{
							day: "Sunday",
							time: "9:00-23:00"
						},
						{
							day: "Monday",
							time: "9:00-23:00"
						},
						{
							day: "Tuesday",
							time: "9:00-23:00"
						},
						{
							day: "Wednesday",
							time: "9:00-23:00"
						},
						{
							day: "Thursday",
							time: "9:00-23:00"
						},
						{
							day: "Friday",
							time: "9:00-23:00"
						},
						{
							day: "Saturday",
							time: "9:00-23:00"
						}
					],
					urls: {
						website: "https://en.espressolab.com",
						facebook: "https://www.facebook.com/EspressolabJo",
						instagram: "https://www.instagram.com/espressolabjo"
					},
					amenities: [
						{
							title: "ACCESSIBILITY",
							items: [
								"Parking",
								"Public transport nearby",
								"Wheelchair accessible"
							]
						},
						{
							title: "FACILITIES",
							items: [
								"Air condition",
								"English Speaking Staff"
							]
						},
						{
							title: "FOOD & BEVERAGE",
							items: [
								"On-site bar"
							]
						},
						{
							title: "EQUIPMENTS",
							items: [
								"Ergo chairs",
								"Monitors",
								"Printer",
								"Chillout room"
							]
						}
					],
					spaces: [
						{
							name: "Shared Desk",
							type: "Shared Desk",
							count: "20"
						},
						{
							name: "Private Desk",
							type: "Private Desk",
							count: "8"
						},
						{
							name: "Private Room",
							type: "Private Room",
							count: "2"
						},
					],
					lat: 31.96583611463314,
					lng: 35.84511972649371
				},
				{
					name: "Seven Pennies",
					pp: "https://media-cdn.tripadvisor.com/media/photo-s/1b/20/e6/7b/first-floor-indoor-seating.jpg",
					premium: false,
					desc: "Seven Pennies is a local coffee house that gives back to its community by donating 7 pennies for every JD spent on drinks.",
					rate: "3.9",
					url: "seven-pennies",
					tags: [
						{
							title: "coworking.card.verified",
							icon: "fas fa-check"
						},
						{
							title: "coworking.card.shared-seats",
							icon: "fas fa-chair"
						}
					],
					address: "Saoud Al-Qadi St., Amman",
					images: [
						"https://media-cdn.tripadvisor.com/media/photo-s/1b/20/e6/7b/first-floor-indoor-seating.jpg",
						"https://lh3.googleusercontent.com/-_9EmZV5xcNA/W2yIO8DKPGI/AAAAAAAAI5o/cDvBQAzp66c5TloL_GVq78hieVwsfqMrACLIBGAYYCw/w768-h768-n-o-k-v1/",
						"https://lh5.googleusercontent.com/p/AF1QipP5NJPmPs9tQKXd4R17rByVSHmnAxTQWbJh9Tvk=w500-h500-k-no"
					],
					workinghours: [
						{
							day: "Sunday",
							time: "9:00-23:00"
						},
						{
							day: "Monday",
							time: "9:00-23:00"
						},
						{
							day: "Tuesday",
							time: "9:00-23:00"
						},
						{
							day: "Wednesday",
							time: "9:00-23:00"
						},
						{
							day: "Thursday",
							time: "9:00-23:00"
						},
						{
							day: "Friday",
							time: "9:00-23:00"
						},
						{
							day: "Saturday",
							time: "9:00-23:00"
						}
					],
					urls: {
						facebook: "https://www.facebook.com/SevenPennies",
						instagram: "https://www.instagram.com/sevenpennies"
					},
					amenities: [
						{
							title: "ACCESSIBILITY",
							items: [
								"Parking",
								"Public transport nearby",
								"Wheelchair accessible"
							]
						},
						{
							title: "FACILITIES",
							items: [
								"Air condition",
								"English Speaking Staff"
							]
						},
						{
							title: "FOOD & BEVERAGE",
							items: [
								"On-site bar"
							]
						},
						{
							title: "EQUIPMENTS",
							items: [
								"Ergo chairs",
								"Monitors",
								"Printer",
								"Chillout room"
							]
						}
					],
					spaces: [
						{
							name: "Shared Desk",
							type: "Shared Desk",
							count: "20"
						},
						{
							name: "Private Desk",
							type: "Private Desk",
							count: "8"
						},
						{
							name: "Private Room",
							type: "Private Room",
							count: "2"
						},
					],
					lat: 31.946103154407286,
					lng: 35.87793457699332
				},
				{
					name: "The Coffee Lab",
					pp: "https://media-cdn.tripadvisor.com/media/photo-s/17/82/16/c0/the-coffee-lab.jpg",
					premium: false,
					desc: "Our goal is to have a welcoming and calm environment to better enjoy our coffee. Whether you are into spending time with friends or work and meetings, our lab should be your destination.",
					rate: "4.0",
					url: "the-coffee-lab",
					tags: [
						{
							title: "coworking.card.verified",
							icon: "fas fa-check"
						},
						{
							title: "coworking.card.shared-seats",
							icon: "fas fa-chair"
						}
					],
					address: "The Y Hotel, Abdul Qader Keshk St., Amman",
					images: [
						"https://media-cdn.tripadvisor.com/media/photo-s/17/82/16/bc/the-coffee-lab.jpg",
						"https://lh3.googleusercontent.com/proxy/jQvp8IjYQy2JnnzTsz6cAZvJtApLwOK0kQ5Ew9y9rC_l8rVDUE20_cOMomXB4SQKne7vpNm86_xBLTm7NY_G4hUXGmfKLXEg-wFlUCaTNQq2u4U",
						"https://lh3.googleusercontent.com/proxy/Aa0heErC6xcGnfrsv-HLcgnDKrsn0f32OGSpl6jCTukTvetrSxc3kxpIGBFrQRVGFpLVW31BfeFiOG0Mgh1l0FuKIlzJL7HGSel-z6llnd6cfgg",
						"https://www.yellowpages.com.jo/uploaded/gallery/upload5b5714b70cfd2.jpeg"
					],
					workinghours: [
						{
							day: "Sunday",
							time: "9:00-23:00"
						},
						{
							day: "Monday",
							time: "9:00-23:00"
						},
						{
							day: "Tuesday",
							time: "9:00-23:00"
						},
						{
							day: "Wednesday",
							time: "9:00-23:00"
						},
						{
							day: "Thursday",
							time: "9:00-23:00"
						},
						{
							day: "Friday",
							time: "9:00-23:00"
						},
						{
							day: "Saturday",
							time: "9:00-23:00"
						}
					],
					urls: {
						facebook: "https://www.facebook.com/thecoffeelabjo",
						instagram: "https://www.instagram.com/thecoffeelab.jordan"
					},
					amenities: [
						{
							title: "ACCESSIBILITY",
							items: [
								"Parking",
								"Public transport nearby",
								"Wheelchair accessible"
							]
						},
						{
							title: "FACILITIES",
							items: [
								"Air condition",
								"English Speaking Staff"
							]
						},
						{
							title: "FOOD & BEVERAGE",
							items: [
								"On-site bar"
							]
						},
						{
							title: "EQUIPMENTS",
							items: [
								"Ergo chairs",
								"Monitors",
								"Printer",
								"Chillout room"
							]
						}
					],
					spaces: [
						{
							name: "Shared Desk",
							type: "Shared Desk",
							count: "20"
						},
						{
							name: "Private Desk",
							type: "Private Desk",
							count: "8"
						},
						{
							name: "Private Room",
							type: "Private Room",
							count: "2"
						},
					],
					lat: 31.953003233005415,
					lng: 35.91929485536143
				},
				{
					name: "Manara - Arts & Culture",
					pp: "http://d30i07b9wlivv4.cloudfront.net/uploads/2018/02/26160949/Manara-Arts-Culture-1.jpg",
					premium: false,
					desc: "Manara Arts & Culture is a cultural hub that celebrates creative endeavors in art, music, literature.",
					rate: "4.2",
					url: "manara-arts-and-culture",
					tags: [
						{
							title: "coworking.card.verified",
							icon: "fas fa-check"
						},
						{
							title: "coworking.card.shared-seats",
							icon: "fas fa-chair"
						}
					],
					address: "Dirar Ben Al-Azwar St. 57،, Amman",
					images: [
						"https://d2az9qivg16qd4.cloudfront.net/2019-08/20190821_172513.jpg",
						"https://images.squarespace-cdn.com/content/v1/591d4c4f03596edea2954778/1572201420787-IA6MT1GHZSIKJG1JRMCS/02.jpeg?format=500w",
						"https://static.wixstatic.com/media/52cf0a_67d120240ddc4b9b91f7fd6c89e58ffb~mv2.jpg/v1/fill/w_640,h_482,al_c,q_80,usm_0.66_1.00_0.01/52cf0a_67d120240ddc4b9b91f7fd6c89e58ffb~mv2.webp"
					],
					workinghours: [
						{
							day: "Sunday",
							time: "9:00-23:00"
						},
						{
							day: "Monday",
							time: "9:00-23:00"
						},
						{
							day: "Tuesday",
							time: "9:00-23:00"
						},
						{
							day: "Wednesday",
							time: "9:00-23:00"
						},
						{
							day: "Thursday",
							time: "9:00-23:00"
						},
						{
							day: "Friday",
							time: "9:00-23:00"
						},
						{
							day: "Saturday",
							time: "9:00-23:00"
						}
					],
					urls: {
						facebook: "https://www.facebook.com/manaraculture",
						instagram: "https://www.instagram.com/manaraculture"
					},
					amenities: [
						{
							title: "ACCESSIBILITY",
							items: [
								"Parking",
								"Public transport nearby",
								"Wheelchair accessible"
							]
						},
						{
							title: "FACILITIES",
							items: [
								"Air condition",
								"English Speaking Staff"
							]
						},
						{
							title: "FOOD & BEVERAGE",
							items: [
								"On-site bar"
							]
						},
						{
							title: "EQUIPMENTS",
							items: [
								"Ergo chairs",
								"Monitors",
								"Printer",
								"Chillout room"
							]
						}
					],
					spaces: [
						{
							name: "Shared Desk",
							type: "Shared Desk",
							count: "20"
						},
						{
							name: "Private Desk",
							type: "Private Desk",
							count: "8"
						},
						{
							name: "Private Room",
							type: "Private Room",
							count: "2"
						},
					],
					lat: 31.955260420380615,
					lng: 35.92410776882195
				}
			],
			DBservices: [
				{
					url: "business-plan",
					name: "Business Plan",
					image: "https://fa42cf2086b5b4ffa910-42905546d373f150b1b6e131d3710cf2.ssl.cf3.rackcdn.com/business-plan-outline.jpg",
					desc: [
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
					]
				},
				{
					url: "financial-plan",
					name: "Financial Plan",
					image: "https://beyond-consult.com/wp-content/uploads/2020/09/key-steps-effective-financial-planning.jpg",
					desc: [
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
					]
				}
			],
			DBtrainings: [
				{
					url: "leadership",
					name: "Leadership",
					image: "https://i1.wp.com/site-valley.com/wp-content/uploads/2021/05/leadership-904624430_383559.jpg",
					desc: [
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
					]
				},
				{
					url: "entrepreneurship",
					name: "Entrepreneurship",
					image: "https://www.gbnews.ch/wp-content/gbnews-uploads/2019/10/entrepreneurship-Nov2.jpg",
					desc: [
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
					]
				}
			],
			DBworkshops: [
				{
					name: "Creative Pricing",
					date: "October 20th, 2021",
					desc: [
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
					],
					image: "https://marketing-insider.eu/wp-content/uploads/2016/08/Creative-Pricing-Strategies-Maximize-Revenues-Marketing-Insider.eu_.jpg",
					url: "creative-pricing"
				},
				{
					name: "Strategic Marketing",
					date: "October 25th, 2021",
					desc: [
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
						"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"
					],
					image: "https://www.admates.net/storage/62/marketing-strategy.jpg",
					url: "strategic-marketing"
				}
			]
		}
	},
	methods: {
		xhrRequest: function(method,url,data,onSuccess,onError) {
			let module = this;
			let fetchUrl = base_url + url;
			return fetch(fetchUrl, {
				method: method,
				headers: {
					"Content-Type": "application/json",
					"Authorization": localStorage.getItem("token")
				},
				body: (data)? JSON.stringify(data): null
			}).then((response) => {
				return new Promise((resolve) => response.json()
					.then((json) => resolve({
						status: response.status,
						ok: response.ok,
						json
					})));
			}).then(({ status, json, ok }) => {
				switch(status){
					case 401:
					case 403: 
						module.showNotification("Error","You don't have permission to access the resource","error");
						module.logout();
						break;
					case 400:
					case 404:
						onError(json);
						break;
					case 500:
						module.showNotification("Error","Ops, something wrong happened","error");
						break;
					default:
						onSuccess(json);
				} 
			}).catch((error) => {
				console.log(error);
				module.showNotification("Error","Ops, something wrong happened","error");
			});
		},
		showNotification: function(title,message,type) {
			let oldNotification = document.querySelector(".el-notification[role='alert']");
			if(oldNotification)
				oldNotification.parentNode.removeChild(oldNotification);
			this.$notify({
				title: title,
				message: message,
				type: type
			});
		},
    getResponsiveState: function() {
			let windowWidth = window.innerWidth;

    	if(windowWidth > 1380)
    		return 5; // XXL
    	if(this.isBetween(windowWidth,1220,1380))
    		return 4; // XL
    	if(this.isBetween(windowWidth,1024,1220))
    		return 3; // L
    	if(this.isBetween(windowWidth,768,1024))
    		return 2; // M
    	if(this.isBetween(windowWidth,480,768))
    		return 1; // S
    	if(windowWidth < 480)
    		return 0; // XS
    },
    isBetween: function(num,from,to) {
    	return num>from&&num<=to;
		},
		isMobile: function() {
		  if(this.getResponsiveState() <= 1)
		    return true;

		  return false;
		},
		getSpaceInfo: function(spaceName) {
			return this.DBspaces.find(space => space.url == spaceName);
		},
		getServiceInfo: function(serviceName) {
			return this.DBservices.find(service => service.url == serviceName);
		},
		getTrainingInfo: function(trainingName) {
			return this.DBtrainings.find(training => training.url == trainingName);
		},
		getWorkshopInfo: function(workshopName) {
			return this.DBworkshops.find(workshop => workshop.url == workshopName);
		}
  }
};
