<template>
  <div class="training-mentorship-cont">
    <div class="hero-cont bg-neutral-black d-flex align-center justify-center relative">
      <img src="../assets/images/services/trainings-mentorship.jpg" alt="trainings mentorship" class="w-100 h-100 absolute rellax" data-rellax-speed="-3">
      <div class="relative">
        <p class="title ft-size-70 ft-neutral-white ft-w-bold text-center center">{{$t("trainings.hero.title")}}</p>
        <div class="specs-cont horizontal layout space-between">
          <div class="width-fit d-flex vertical layout justify-center align-center">
            <i class="fas fa-user-tie ft-size-40 ft-neutral-white"></i>
            <p class="spec-title ft-size-18 ft-neutral-white ft-w-bold text-center">{{$t("trainings.hero.spec1.title")}}</p>
            <p class="ft-size-14 ft-neutral-white ft-w-bold text-center m-0">{{$t("trainings.hero.spec1.subtitle")}}</p>
          </div>
          <div class="width-fit d-flex vertical layout justify-center align-center">
            <i class="fas fa-certificate ft-size-40 ft-neutral-white"></i>
            <p class="spec-title ft-size-18 ft-neutral-white ft-w-bold text-center">{{$t("trainings.hero.spec2.title")}}</p>
            <p class="ft-size-14 ft-neutral-white ft-w-bold text-center m-0">{{$t("trainings.hero.spec2.subtitle")}}</p>
          </div>
          <div class="width-fit d-flex vertical layout justify-center align-center">
            <i class="fas fa-chart-bar ft-size-40 ft-neutral-white"></i>
            <p class="spec-title ft-size-18 ft-neutral-white ft-w-bold text-center">{{$t("trainings.hero.spec3.title")}}</p>
            <p class="ft-size-14 ft-neutral-white ft-w-bold text-center m-0">{{$t("trainings.hero.spec3.subtitle")}}</p>
          </div>
        </div>
        <button class="primary ft-size-26 ft-w-bold border-radius-32 d-block shadow" v-on:click="scrollToTrainings">{{$t("trainings.hero.cta")}} <i class="fas fa-arrow-down bounce relative"></i></button>
      </div>
    </div>
    <div class="statistics-cont">
      <div class="wrapper w-100 horizontal layout justify-center">
        <div class="statistic shadow horizontal layout align-center bg-primary border-radius-6 ft-neutral-white">
          <i class="fas fa-list-ul ft-size-24"></i>
          <div class="vertical layout">
            <p class="ft-size-20 ft-w-bold m-0">+20</p>
            <p class="ft-size-16 m-0">{{$t("trainings.statistics.training-programs")}}</p>
          </div>
        </div>
        <div class="statistic shadow horizontal layout align-center bg-primary border-radius-6 ft-neutral-white">
          <i class="fas fa-users ft-size-24"></i>
          <div class="vertical layout">
            <p class="ft-size-20 ft-w-bold m-0">+500</p>
            <p class="ft-size-16 m-0">{{$t("trainings.statistics.enrolled-trainees")}}</p>
          </div>
        </div>
        <div class="statistic shadow horizontal layout align-center bg-primary border-radius-6 ft-neutral-white">
          <i class="fas fa-user-tie ft-size-24"></i>
          <div class="vertical layout">
            <p class="ft-size-20 ft-w-bold m-0">+12</p>
            <p class="ft-size-16 m-0">{{$t("trainings.statistics.certified-trainers-coaches")}}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="trainings-cont">
      <div class="wrapper">
        <div class="trainings-inner-cont">
          <el-carousel type="card" arrow="always" v-bind:autoplay="false" class="w-100 trainings">
            <el-carousel-item v-for="(training,index) in trainings" v-bind:key="index">
              <training-card v-bind:training="training"></training-card>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
    <div class="our-partners-cont bg-light-grey">
      <div class="wrapper">
        <p class="ft-size-30 ft-grey ft-w-bold text-center width-fit relative">{{$t("business-incubators.our-strategic-partners")}}</p>
        <div class="partners-cont horizontal layout wrap space-evenly">
          <a href="https://www.zain.com/en" target="_blank"><img src="../assets/images/strategic-partners/zain.png" alt="zain"></a>
          <a href="https://www.zain.com/en" target="_blank"><img src="../assets/images/strategic-partners/zain.png" alt="zain"></a>
          <a href="https://www.zain.com/en" target="_blank"><img src="../assets/images/strategic-partners/zain.png" alt="zain"></a>
          <a href="https://www.zain.com/en" target="_blank"><img src="../assets/images/strategic-partners/zain.png" alt="zain"></a>
        </div>
      </div>
    </div>
    <div class="our-trainers-cont bg-neutral-white">
      <div class="wrapper">
        <p class="ft-size-30 ft-grey ft-w-bold text-center width-fit relative">{{$t("trainings.trainers-network")}}</p>
        <div class="trainers-cont horizontal layout wrap justify-center">
          <trainer-card v-for="(trainer,index) in trainers" v-bind:key="index" v-bind:trainer="trainer"></trainer-card>
        </div>
        <button class="primary ft-size-18 ft-w-bold border-radius-32 d-block shadow center" v-on:click="$router.push({path:'/trainings-and-mentorship/trainers-network'})">{{$t("shared.view-all")}}</button>
      </div>
    </div>
    <div class="workshops-events-cont bg-light-grey">
      <div class="wrapper">
        <p class="ft-size-30 ft-grey ft-w-bold text-center width-fit relative">{{$t("business-incubators.workshops-events")}}</p>
        <div class="inner-cont">
          <el-carousel type="card" arrow="always" v-bind:autoplay="false" class="w-100">
            <el-carousel-item v-for="(workshop,index) in workshops" v-bind:key="index">
              <workshop-card v-bind:workshop="workshop"></workshop-card>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import Rellax from "rellax";
  import {Carousel,CarouselItem} from "element-ui";
  import TrainingCard from "../components/TrainingsMentorship/TrainingCard.vue";
  import TrainerCard from "../components/TrainingsMentorship/TrainerCard.vue";
  import WorkshopCard from "../components/businessincubators/WorkshopCard.vue";

  Vue.component("training-card",TrainingCard);
  Vue.component("trainer-card",TrainerCard);
  Vue.component("workshop-card",WorkshopCard);
  Vue.component("el-carousel",Carousel);
  Vue.component("el-carousel-item",CarouselItem);

  export default {
    name: "training-mentorship",
    components: {
      TrainingCard,
      TrainerCard,
      WorkshopCard,
      Carousel,
      CarouselItem
    },
    mounted() {
      new Rellax('.rellax');
    },
    data() {
      return {
        trainings: [
          {
            name: this.$t("trainings.trainings.leadership.title"),
            desc: this.$t("trainings.trainings.leadership.desc"),
            image: "https://i1.wp.com/site-valley.com/wp-content/uploads/2021/05/leadership-904624430_383559.jpg",
            url: "leadership"
          },
          {
            name: this.$t("trainings.trainings.entrepreneurship.title"),
            desc: this.$t("trainings.trainings.entrepreneurship.desc"),
            image: "https://www.gbnews.ch/wp-content/gbnews-uploads/2019/10/entrepreneurship-Nov2.jpg",
            url: "entrepreneurship"
          },
          {
            name: this.$t("trainings.trainings.leadership.title"),
            desc: this.$t("trainings.trainings.leadership.desc"),
            image: "https://i1.wp.com/site-valley.com/wp-content/uploads/2021/05/leadership-904624430_383559.jpg",
            url: "leadership"
          },
          {
            name: this.$t("trainings.trainings.entrepreneurship.title"),
            desc: this.$t("trainings.trainings.entrepreneurship.desc"),
            image: "https://www.gbnews.ch/wp-content/gbnews-uploads/2019/10/entrepreneurship-Nov2.jpg",
            url: "entrepreneurship"
          },
        ],
        workshops: [
          {
            name: this.$t("business-incubators.workshops.creative-pricing.title"),
            desc: this.$t("business-incubators.workshops.creative-pricing.desc"),
            date: "October 19th, 2021",
            image: "https://marketing-insider.eu/wp-content/uploads/2016/08/Creative-Pricing-Strategies-Maximize-Revenues-Marketing-Insider.eu_.jpg",
            url: "creative-pricing"
          },
          {
            name: this.$t("business-incubators.workshops.strategic-marketing.title"),
            desc: this.$t("business-incubators.workshops.strategic-marketing.desc"),
            date: "October 25th, 2021",
            image: "https://www.admates.net/storage/62/marketing-strategy.jpg",
            url: "strategic-marketing"
          },
          {
            name: this.$t("business-incubators.workshops.creative-pricing.title"),
            desc: this.$t("business-incubators.workshops.creative-pricing.desc"),
            date: "October 19th, 2021",
            image: "https://marketing-insider.eu/wp-content/uploads/2016/08/Creative-Pricing-Strategies-Maximize-Revenues-Marketing-Insider.eu_.jpg",
            url: "creative-pricing"
          },
          {
            name: this.$t("business-incubators.workshops.strategic-marketing.title"),
            desc: this.$t("business-incubators.workshops.strategic-marketing.desc"),
            date: "October 25th, 2021",
            image: "https://www.admates.net/storage/62/marketing-strategy.jpg",
            url: "strategic-marketing"
          }
        ],
        trainers: [
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan",
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan",
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan",
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan",
            bio: "A specialized trainer in web development modern practices and recent frameworks. Covers all topics from beginners topics to advanced ones.",
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          }
        ]
      }
    },
    methods: {
      scrollToTrainings: function() {
        let target = this.$el.querySelector(".trainings-cont");
        let scrollToPosition = target.getBoundingClientRect().top + window.pageYOffset - 80;

        window.scrollTo({
          top: scrollToPosition, 
          behavior: "smooth"
        });
      }
    }
  };  
</script>

<style scoped lang="scss">
  .training-mentorship-cont{
    .hero-cont{
      height: 600px;
      overflow: hidden;
      img{
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
        opacity: 0.7;
      }
      .title{
        text-shadow: 0 0px 8px rgba(0,0,0,0.5);
        max-width: 750px;
      }
      .specs-cont{
        margin-top: 80px;
        .spec-title{
          margin: 18px auto 4px auto;
        }
        p,i{
          text-shadow: 0 0px 8px rgba(0,0,0,0.5);
        }
      }
      button{
        padding: 12px 32px;
        margin: 46px auto 0 auto;
        .bounce {
          animation: bounce 2s infinite;
        }

        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-10px);
          }
          60% {
            transform: translateY(-5px);
          }
        }
      }
    }
    .statistics-cont{
      padding: 50px 0 0 0;
      .statistic{
        margin: 0 12px;
        padding: 8px 14px;
      }
    }
    .trainings-cont{
      padding: 50px 0;
      .el-carousel{
        .el-carousel__item{
          opacity: 0.5;
          pointer-events: none;
          &.is-active{
            opacity: 1;
            pointer-events: all;
          }
        }
      }
    }
    .our-partners-cont{
      padding: 50px 0;
      .wrapper{
        p{
          margin: 0 auto 50px auto;
          &::after,&::before{
            position: absolute;
            width: 345px;
            height: 3px;
            bottom: -12px;
            background-color: var(--primary);
            content: '';
            border-left: solid 5px #f1f1f1;
            border-right: solid 5px #f1f1f1;
          }
          &::before{
            border: none;
            width: 365px; 
          }
        }
        .partners-cont{
          a{
            margin: 24px;
            img{
              width: 100%;
              max-height: 100px;
              max-width: 200px;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
    .our-trainers-cont{
      padding: 50px 0;
      .wrapper{
        p{
          margin: 0 auto 50px auto;
          &::after,&::before{
            position: absolute;
            width: 260px;
            height: 3px;
            bottom: -12px;
            background-color: var(--primary);
            content: '';
            border-left: solid 5px #f1f1f1;
            border-right: solid 5px #f1f1f1;
          }
          &::before{
            border: none;
            width: 280px; 
          }
        }
        button{
          padding: 6px 40px;
        }
      }
    }
    .workshops-events-cont{
      padding: 50px 0;
      .wrapper{
        p{
          margin: 0 auto 50px auto;
          &::after,&::before{
            position: absolute;
            width: 300px;
            height: 3px;
            bottom: -12px;
            background-color: var(--primary);
            content: '';
            border-left: solid 5px #f1f1f1;
            border-right: solid 5px #f1f1f1;
          }
          &::before{
            border: none;
            width: 320px; 
          }
        }
        .el-carousel{
          .el-carousel__item{
            opacity: 0.5;
            pointer-events: none;
            &.is-active{
              opacity: 1;
              pointer-events: all;
            }
          }
        }
      }
    }
  }

  body[dir="ltr"] {
    .training-mentorship-cont{
      .hero-cont{
        button{
          i{
            margin-left: 8px;
          }
        }
      }
      .statistics-cont{
        .statistic{
          i{
            margin-right: 12px;
          }
        }
      }
      .our-partners-cont{
        .wrapper{
          p{
            &::after,&::before{
              left: 12px;
            }
            &::before{
              left: 7px;
            }
          }
        }
      }
      .our-trainers-cont{
        .wrapper{
          p{
            &::after,&::before{
              left: 12px;
            }
            &::before{
              left: 7px;
            }
          }
        }
      }
      .workshops-events-cont{
        .wrapper{
          p{
            &::after,&::before{
              left: 12px;
            }
            &::before{
              left: 7px;
            }
          }
        }
      }
    }
  }

  body[dir="rtl"] {
    .training-mentorship-cont{
      .hero-cont{
        button{
          i{
            margin-right: 8px;
          }
        }
      }
      .statistics-cont{
        .statistic{
          i{
            margin-left: 12px;
          }
        }
      }
      .our-partners-cont{
        .wrapper{
          p{
            &::after,&::before{
              right: -45px;
            }
            &::before{
              right: -50px;
            }
          }
        }
      }
      .our-trainers-cont{
        .wrapper{
          p{
            &::after,&::before{
              right: -40px;
            }
            &::before{
              right: -45px;
            }
          }
        }
      }
      .workshops-events-cont{
        .wrapper{
          p{
            &::after,&::before{
              right: -10px;
            }
            &::before{
              right: -15px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px){
    .training-mentorship-cont{
      .hero-cont{
        height: unset;
        .title{
          font-size: 40px;
          max-width: 90%;
          margin-top: 40px;
        }
        .specs-cont{
          margin-top: 45px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &>div{
            padding: 0 10px;
            margin: 0 0 20px 0;
            i{
              font-size: 30px;
            }
            .spec-title{
              font-size: 16px;
              margin: 10px auto 4px auto;
            }
          }
        }
        button{
          font-size: 18px;
          margin: 12px auto 28px auto;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .training-mentorship-cont{
      .statistics-cont{
        padding: 24px 0 0 0;
        .wrapper{
          flex-direction: column;
          .statistic{
            margin: 0 0 8px 0;
          }
        }
      }
      .our-partners-cont{
        padding: 32px 0;
        .wrapper{
          p{
            font-size: 22px;
            margin: 0 auto 40px auto;
            &::after,&::before{
              width: 295px;
            }
            &::before{
              width: 315px; 
            }
          }
          .partners-cont{
            a{
              margin: 12px;
              img{
                max-height: 50px;
                max-width: 100px;
              }
            }
          }
        }
      }
      .our-trainers-cont{
        padding: 32px 0;
        .wrapper{
          p{
            font-size: 22px;
            margin: 0 auto 40px auto;
            &::after,&::before{
              width: 295px;
            }
            &::before{
              width: 315px; 
            }
          }
        }
      }
      .workshops-events-cont{
        padding: 32px 0;
        .wrapper{
          p{
            font-size: 22px;
            margin: 0 auto 40px auto;
            &::after,&::before{
              width: 295px;
            }
            &::before{
              width: 315px; 
            }
          }
        }
      }
    }

    body[dir="ltr"] {
      .training-mentorship-cont{
        .our-partners-cont{
          .wrapper{
            p{
              &::after,&::before{
                left: -17px;
              }
              &::before{
                left: -22px;
              }
            }
          }
        }
        .our-trainers-cont{
          .wrapper{
            p{
              &::after,&::before{
                left: -45px;
              }
              &::before{
                left: -50px;
              }
            }
          }
        }
        .workshops-events-cont{
          .wrapper{
            p{
              &::after,&::before{
                left: -34px;
              }
              &::before{
                left: -39px;
              }
            }
          }
        }
      }
    }

    body[dir="rtl"] {
      .training-mentorship-cont{
        .our-partners-cont{
          .wrapper{
            p{
              &::after,&::before{
                right: -60px;
              }
              &::before{
                right: -65px;
              }
            }
          }
        }
        .our-trainers-cont{
          .wrapper{
            p{
              &::after,&::before{
                right: -85px;
              }
              &::before{
                right: -90px;
              }
            }
          }
        }
        .workshops-events-cont{
          .wrapper{
            p{
              &::after,&::before{
                right: -50px;
              }
              &::before{
                right: -55px;
              }
            }
          }
        }
      }
    }
  }
</style>