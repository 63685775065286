<template>
  <div class="space-page-cont">
    <div v-if="validSpace" class="valid-space-cont">
      <div class="hero-cont bg-neutral-black vertical layout align-center justify-center relative">
        <img v-bind:src="space.pp" alt="coworking spaces" class="w-100 h-100 absolute rellax" data-rellax-speed="-3">
        <div class="relative w-100">
          <p class="title ft-size-70 ft-neutral-white ft-w-bold text-center center">{{space.name}}</p>
        </div>
        <div class="hot-links-cont w-100 horizontal layout wrap justify-center align-center absolute">
          <div class="pointer" v-on:click="scrollTo('overview-cont')">
            <i class="fas fa-eye ft-size-24 ft-neutral-white text-center transition-300"></i>
            <p class="ft-size-16 ft-neutral-white ft-w-bold text-center transition-300">{{$t("space.tabs.overview")}}</p>
          </div>
          <div class="pointer" v-on:click="scrollTo('spaces-cont')">
            <i class="fas fa-chair ft-size-24 ft-neutral-white text-center transition-300"></i>
            <p class="ft-size-16 ft-neutral-white ft-w-bold text-center transition-300">{{$t("space.tabs.spaces")}}</p>
          </div>
          <div class="pointer" v-on:click="scrollTo('amenities-cont')">
            <i class="fas fa-box-open ft-size-24 ft-neutral-white text-center transition-300"></i>
            <p class="ft-size-16 ft-neutral-white ft-w-bold text-center transition-300">{{$t("space.tabs.amenities")}}</p>
          </div>
          <div class="pointer" v-on:click="scrollTo('photos-cont')">
            <i class="fas fa-images ft-size-24 ft-neutral-white text-center transition-300"></i>
            <p class="ft-size-16 ft-neutral-white ft-w-bold text-center transition-300">{{$t("space.tabs.photos")}}</p>
          </div>
          <div class="pointer" v-on:click="scrollTo('address-cont')">
            <i class="fas fa-map-marked-alt ft-size-24 ft-neutral-white text-center transition-300"></i>
            <p class="ft-size-16 ft-neutral-white ft-w-bold text-center transition-300">{{$t("space.tabs.address")}}</p>
          </div>
          <div class="pointer" v-on:click="scrollTo('reviews-cont')">
            <i class="fas fa-comment-dots ft-size-24 ft-neutral-white text-center transition-300"></i>
            <p class="ft-size-16 ft-neutral-white ft-w-bold text-center transition-300">{{$t("space.tabs.reviews")}}</p>
          </div>
        </div>
      </div>
      <div class="bg-light-grey">
        <div class="wrapper horizontal layout space-between relative">
          <div class="sections-cont">
            <div class="overview-cont section">
              <p class="title ft-size-24 ft-primary ft-w-bold">{{$t("space.tabs.overview")}}</p>
              <div class="horizontal layout bg-neutral-white shadow border-radius-2">
                <div class="flex">
                  <p class="desc ft-size-18 ft-grey">{{space.desc}}</p>
                  <div class="working-hours-cont">
                    <p class="title ft-size-18 ft-primary"><i class="far fa-clock"></i> {{$t("shared.working-hours")}}</p>
                    <div class="tuple ft-size-14 ft-grey" v-for="(day,index) in space.workinghours" v-bind:key="index">
                      <p class="ft-w-bold m-0">{{day.day}}</p>
                      <span>{{day.time}}</span>
                    </div>
                  </div>
                  <div v-if="space.urls" class="social-media-links horizontal layout align-center justify-center">
                    <button v-if="space.urls.website" class="primary ft-size-16 ft-w-bold shadow" v-on:click="goToSite(space.urls.website)"><i class="fas fa-link"></i> {{$t("shared.website")}}</button>
                    <button v-if="space.urls.facebook" class="primary ft-size-16 ft-w-bold shadow" v-on:click="goToSite(space.urls.facebook)"><i class="fab fa-facebook"></i> {{$t("shared.facebook")}}</button>
                    <button v-if="space.urls.instagram" class="primary ft-size-16 ft-w-bold shadow" v-on:click="goToSite(space.urls.instagram)"><i class="fab fa-instagram"></i> {{$t("shared.instagram")}}</button>
                  </div>
                </div>
                <div class="space-specs vertical layout justify-center align-center">
                  <div class="progress-cont">
                    <el-progress type="dashboard" v-bind:percentage="percentage" color="#67c23a" v-bind:format="format"></el-progress>
                  </div>
                  <div class="tags-cont">
                    <div v-for="(tag,index) in space.tags" v-bind:key="index" class="tag horizontal layout align-center">
                      <i v-bind:class="tag.icon" class="ft-size-14 ft-grey ft-w-bold"></i>
                      <p class="ft-size-14 ft-grey ft-w-bold">{{tagTitle(index)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr/>
            <div class="spaces-cont section">
              <p class="title ft-size-24 ft-primary ft-w-bold">{{$t("space.tabs.spaces")}}</p>
              <div class="bg-neutral-white shadow border-radius-2">
                <div v-for="(spaceItem,index) in space.spaces" v-bind:key="index" class="horizontal layout align-center">
                  <!-- icons from https://icons8.com -->
                  <img v-if="spaceItem.type == 'Shared Desk'" src="../../assets/images/icons/desk_shared.png" v-bind:alt="spaceItem.name">
                  <img v-if="spaceItem.type == 'Private Desk'" src="../../assets/images/icons/desk.png" v-bind:alt="spaceItem.name">
                  <img v-if="spaceItem.type == 'Private Room'" src="../../assets/images/icons/door.png" v-bind:alt="spaceItem.name">
                  <p v-if="spaceItem.type == 'Shared Desk'" class="name ft-size-16 ft-grey">{{$t("space.types.shared-desk")}}</p>
                  <p v-if="spaceItem.type == 'Private Desk'" class="name ft-size-16 ft-grey">{{$t("space.types.private-desk")}}</p>
                  <p v-if="spaceItem.type == 'Private Room'" class="name ft-size-16 ft-grey">{{$t("space.types.private-room")}}</p>
                  <p class="count ft-size-16 ft-grey ft-w-bold">x{{spaceItem.count}}</p>
                  <span class="flex"></span>
                  <button class="primary ft-size-16 ft-w-bold shadow">{{$t("space.book.book")}}</button>
                </div>
              </div>
            </div>
            <hr/>
            <div class="amenities-cont section">
              <p class="title ft-size-24 ft-primary ft-w-bold">{{$t("space.tabs.amenities")}}</p>
              <div class="amenities-inner-cont bg-neutral-white shadow border-radius-2">
                <div class="amenity" v-for="(amenity,index) in space.amenities" v-bind:key="index">
                  <p class="title ft-size-18 ft-primary">{{amenity.title}}</p>
                  <p class="ft-size-16 ft-grey" v-for="(amenityItem,amenityIndex) in amenity.items" v-bind:key="amenityIndex">{{amenityItem}}</p>
                </div>
              </div>
            </div>
            <hr/>
            <div class="photos-cont section">
              <p class="title ft-size-24 ft-primary ft-w-bold">{{$t("space.tabs.photos")}}</p>
              <el-carousel v-bind:interval="5000" arrow="always" trigger="click">
                <el-carousel-item v-for="(image,index) in space.images" v-bind:key="index">
                  <img v-bind:src="image" v-bind:alt="space.name" class="w-100 h-100 border-radius-2">
                </el-carousel-item>
              </el-carousel>
            </div>
            <hr/>
            <div class="address-cont section">
              <p class="title ft-size-24 ft-primary ft-w-bold">{{$t("space.tabs.address")}}</p>
              <div class="bg-neutral-white shadow border-radius-2">
                <p class="ft-size-18 ft-grey">{{space.address}}</p>
                <l-map v-bind:zoom="zoom" v-bind:center="latlng" v-bind:options="mapOptions" style="height: 400px">
                  <l-tile-layer v-bind:url="url"/>
                  <l-marker :lat-lng="latlng"></l-marker>
                </l-map>
              </div>
            </div>
            <hr/>
            <div class="reviews-cont section">
              <p class="title ft-size-24 ft-primary ft-w-bold">{{$t("space.tabs.reviews")}}</p>
              <div v-if="space.reviews">
                <review-card v-for="(review,index) in space.reviews" v-bind:key="index" v-bind:review="review"></review-card>
              </div>
              <p v-else class="ft-size-18 ft-grey text-center">{{space.name}} {{$t("space.no-reviews")}}.</p>
            </div>
          </div>
          <div class="book-now-cont shadow bg-neutral-white height-fit border-radius-2 sticky">
            <p class="ft-size-20 ft-grey ft-w-bold m-0">{{$t("space.book.title")}}</p>
            <p class="desc ft-size-14 ft-grey">{{$t("space.book.sub-title")}}</p>
            <button class="primary ft-size-20 ft-w-bold d-block shadow">{{$t("space.book.book-now")}}</button>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="invalid-space-cont">
      <div class="wrapper">
        <i class="ft-size-70 ft-red fas fa-exclamation text-center"></i>
        <p class="title ft-size-24 ft-grey text-center">{{$t("space.invalid.not-found")}}</p>
        <button class="primary ft-size-20 ft-w-bold shadow width-fit d-block" v-on:click="$router.push({path: '/coworking-spaces'})">{{$t("space.invalid.back-to-spaces")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import ReviewCard from "../../components/coworking/ReviewCard.vue";
  import Rellax from "rellax";
  import {Carousel,CarouselItem,Progress} from "element-ui";
  import "leaflet/dist/leaflet.css";
  import {latLng} from "leaflet";
  import {LMap,LTileLayer,LMarker} from "vue2-leaflet";

  import { Icon } from "leaflet";
  delete Icon.Default.prototype._getIconUrl;
  Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
  });
  
  Vue.component("review-card",ReviewCard);
  Vue.component("el-carousel",Carousel);
  Vue.component("el-carousel-item",CarouselItem);
  Vue.component("el-progress",Progress);
  Vue.component("l-map", LMap);
  Vue.component("l-tile-layer", LTileLayer);
  Vue.component("l-marker", LMarker);

  export default {
    name: "space-page",
    components: {
      ReviewCard,
      Carousel,
      CarouselItem,
      Progress,
      LMap,
      LTileLayer,
      LMarker
    },
    created() {
      this.space = this.getSpaceInfo(this.$route.params.space);
      if(this.space)
        document.title = "THE GRID | " + this.space.name;
      else
        this.validSpace = false;
    },
    mounted() {
      new Rellax('.rellax');
    },
    computed: {
      percentage: function() {
        let percentage = 0;
        percentage = (parseFloat(this.space.rate) / 5) * 100;
        
        return percentage;
      },
      latlng: function() {
        return latLng(this.space.lat,this.space.lng);
      }
    },
    data() {
      return {
        validSpace: true,
        space: {},
        zoom: 13,
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        mapOptions: {
          zoomSnap: 0.5,
          attributionControl: false
        }
      }
    },
    methods: {
      format: function() {
        return this.space.rate;
      },
      tagTitle: function(index) {
        return this.$t(this.space.tags[index].title);
      },
      goToSite: function(site) {
        window.open(site,"_blank").focus();
      },
      scrollTo: function(destination) {
        let target = this.$el.querySelector("." + destination);
        let scrollToPosition = target.getBoundingClientRect().top + window.pageYOffset - 80;

        window.scrollTo({
          top: scrollToPosition, 
          behavior: "smooth"
        });
      }
    }
  };
</script>

<style scoped lang="scss">
  .space-page-cont{
    .valid-space-cont{
      .hero-cont{
        height: 400px;
        overflow: hidden;
        img{
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center center;
          opacity: 0.7;
        }
        .title{
          text-shadow: 0 0px 8px rgba(0,0,0,0.5);
          max-width: 750px;
        }
        .hot-links-cont{
          z-index: 1;
          bottom: 0;
          background-color: #0000009c;
          padding: 10px 0;
          &>div{
            margin: 0 28px;
            i{
              display: block;
              text-shadow: 0 0px 8px rgba(0,0,0,0.5);
            }
            p{
              margin: 8px 0 0 0;
              text-shadow: 0 0px 8px rgba(0,0,0,0.5);
            }
            &:hover,&:active,&:focus{
              i,p{
                color: var(--secondary);
              }
            }
          }
        }
      }
      .sections-cont{
        width: calc(100% - 350px);
        padding-bottom: 30px;
        .section{
          padding: 32px 0;
          .title{
            margin-top: 0;
          }
        }
        hr{
          border: 0;
          border-top: 1px solid #d4d4d4;
          max-width: 500px;
          width: calc(100% - 32px);
        }
        .overview-cont{
          &>div{
            padding: 30px 20px;
            .desc{
              line-height: 24px;
              margin: 0 0 0 0;
            }
            .working-hours-cont{
              .title{
                margin: 32px 0 12px 0;
              }
              .tuple{
                margin: 6px 0 0 0;
                p{
                  display: inline-block;
                  width: 100px;
                }
              }
            }
            .social-media-links{
              margin-top: 32px;
              button{
                padding: 6px 14px;
                margin: 0 8px;
              }
            }
            .space-specs{
              width: 180px;
              padding: 0 16px;
              margin: 24px 0;
              .tags-cont{
                i{
                  margin: -2px 8px 0 8px;
                }
                p{
                  margin: 8px 0;
                }
              }
            }
          }
        }
        .spaces-cont{
          &>div{
            padding: 20px;
            &>div{
              border-bottom: 1px solid #d4d4d4;
              padding: 16px 0;
              .name{
                margin: 0 10px;
                width: 100px;
              }
              img{
                width: 50px;
                height: 50px;
                object-fit: contain;
                object-position: center;
              }
              button{
                padding: 6px 32px;
              }
              &:last-of-type{
                border: 0;
              }
            }
          }
        }
        .amenities-cont{
          .amenities-inner-cont{
            padding: 30px 20px;
            .amenity{
              margin-bottom: 30px;
              p{
                margin: 4px 0 0 0;
              }
              .title{
                margin: 0 0 12px 0;
                text-decoration: underline;
              }
              &:last-of-type{
                margin: 0;
              }
            }
          }
        }
        .photos-cont{
          img{
            object-fit: cover;
            object-position: center;
          }
        }
        .address-cont{
          &>div{
            padding: 30px 20px;
            &>p{
              margin: 0 0 16px 0;
            }
          }
        }
      }
      .book-now-cont{
        width: 325px;
        padding: 24px;
        margin: 83px 0 86px 0;
        top: 130px;
        .desc{
          margin: 4px 0 32px 0;
        }
        button{
          margin: 0 auto;
          padding: 6px 32px;
        }
      }
    }
    .invalid-space-cont{
      padding: 100px 0;
      i{
        display: block;
      }
      .title{
        margin: 16px auto 0 auto;
        max-width: 500px;
      }
      button{
        margin: 40px auto;
        padding: 12px 28px;
      }
    }
  }

  body[dir="ltr"] {
    .space-page-cont{
      .valid-space-cont{
        .sections-cont{
          .overview-cont{
            &>div{
              .space-specs{
                border-left: 1px solid #f1f1f1;
              }
            }
          }
        }
      }
    }
  }

  body[dir="rtl"] {
    .space-page-cont{
      .valid-space-cont{
        .sections-cont{
          .overview-cont{
            &>div{
              .space-specs{
                border-right: 1px solid #f1f1f1;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 950px){
    .space-page-cont{
      .valid-space-cont{
        .sections-cont{
          width: 100%;
        }
        .book-now-cont{
          display: none;
        }
      }
    } 
  }

  @media only screen and (max-width: 768px){
    .space-page-cont{
      .valid-space-cont{
        .hero-cont{
          height: 400px;
          .title{
            font-size: 40px;
            max-width: 90%;
            margin-top: 0;
          }
          .hot-links-cont{
            padding: 4px 0;
            &>div{
              margin: 5px 20px;
              i{
                font-size: 18px;
              }
              p{
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px){
    .space-page-cont{
      .valid-space-cont{
        .sections-cont{
          .overview-cont{
            &>div{
              flex-direction: column;
              .social-media-links{
                flex-direction: column;
                button{
                  width: 100%;
                  margin: 4px 0;
                }
              }
              .space-specs{
                margin: 24px auto;
                border: none!important;
              }
            }
          }
          .spaces-cont{
            &>div{
              padding: 20px 14px;
            }
          }
        } 
      }
    } 
  }

  @media only screen and (max-width: 575px){
    .space-page-cont{

    }
  }
</style>