<template>
  <div class="training-page-cont">
    <div v-if="validTraining" class="valid-training-cont">
      <div class="hero-cont bg-neutral-black vertical layout align-center justify-center relative">
        <img v-bind:src="training.image" alt="business trainings" class="w-100 h-100 absolute rellax" data-rellax-speed="-3">
        <div class="relative w-100">
          <p class="title ft-size-70 ft-neutral-white ft-w-bold text-center center">{{training.name}}</p>
        </div>
      </div>
      <div class="bg-light-grey">
        <div class="wrapper horizontal layout space-between relative">
          <div class="sections-cont">
            <div class="overview-cont section">
              <p class="title ft-size-24 ft-primary ft-w-bold">{{$t("space.tabs.overview")}}</p>
              <div class="bg-neutral-white shadow border-radius-2">
                <p v-for="(desc,index) in training.desc" v-bind:key="index" class="desc ft-size-18 ft-grey">{{desc}}</p>
                <div class="contact-us-cont horizontal layout space-between">
                  <span class="flex"></span>
                  <div class="horizontal layout align-center">
                    <p class="ft-size-16 ft-grey ft-w-bold flex">{{$t("training.learn-more")}}</p>
                    <button class="primary ft-size-16 ft-w-bold shadow">{{$t("shared.contact-us")}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="invalid-training-cont">
      <div class="wrapper">
        <i class="ft-size-70 ft-red fas fa-exclamation text-center"></i>
        <p class="title ft-size-24 ft-grey text-center">{{$t("training.invalid.not-found")}}</p>
        <button class="primary ft-size-20 ft-w-bold shadow width-fit d-block" v-on:click="$router.push({path: '/trainings-and-mentorship'})">{{$t("training.invalid.back-to-trainings")}}</button>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import Rellax from "rellax";

  export default {
    name: "training-page",
    created() {
      this.training = this.getTrainingInfo(this.$route.params.training);
      if(this.training)
        document.title = "THE GRID | " + this.training.name;
      else
        this.validTraining = false;
    },
    mounted() {
      new Rellax('.rellax');
    },
    data() {
      return {
        validTraining: true,
        training: {}
      }
    },
    methods: {

    }
  };  
</script>

<style scoped lang="scss">
  .training-page-cont{
    .valid-training-cont{
      .hero-cont{
        height: 400px;
        overflow: hidden;
        img{
          top: 0;
          left: 0;
          object-fit: cover;
          object-position: center center;
          opacity: 0.7;
        }
        .title{
          text-shadow: 0 0px 8px rgba(0,0,0,0.5);
          max-width: 750px;
        }
      }
      .sections-cont{
        padding-bottom: 30px;
        .section{
          padding: 32px 0;
          .title{
            margin-top: 0;
          }
        }
        .overview-cont{
          &>div{
            padding: 30px 20px;
            .desc{
              line-height: 24px;
              margin: 0 0 32px 0;
            }
            .contact-us-cont{
              p{
                margin: 0 14px;
              }
              button{
                padding: 8px 16px;
              }
            }
          }
        }
      }
    }
    .invalid-training-cont{
      padding: 100px 0;
      i{
        display: block;
      }
      .title{
        margin: 16px auto 0 auto;
        max-width: 500px;
      }
      button{
        margin: 40px auto;
        padding: 12px 28px;
      }
    }
  }

  @media only screen and (max-width: 768px){
    .training-page-cont{
      .valid-training-cont{
        .hero-cont{
          height: 400px;
          .title{
            font-size: 40px;
            max-width: 90%;
            margin-top: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .training-page-cont{

    }
  }
</style>