<template>
  <div class="workshop-card-cont h-100 w-100 relative" v-bind:style="{'background-image': 'URL(' + workshop.image + ')'}">
    <div class="content horizontal layout space-between absolute w-100 align-end relative">
      <div class="vertical layout flex">
        <div class="title-cont horizontal layout space-between">
          <p class="title ft-size-20 ft-primary ft-w-bold">{{workshop.name}}</p>
          <p class="country ft-size-16 ft-primary m-0 absolute">{{workshop.date}}</p>
        </div>
        <p class="desc ft-size-16 ft-grey m-0">{{workshop.desc}}</p>
      </div>
      <button class="primary ft-size-18 ft-w-bold border-radius-32 d-block shadow height-fit" v-on:click="goToWorkshopPage">{{$t("shared.read-more")}}</button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "workshop-card",
    mounted() {
      
    },
    props: {
      workshop: null
    },
    data() {
      return {

      }
    },
    methods: {
      goToWorkshopPage: function() {
        let path = "/workshops-events/" + this.workshop.url;
        this.$router.push({path: path});
      }
    }
  };  
</script>

<style scoped lang="scss">
  .workshop-card-cont{
    background-position: center center;
    background-size: cover;
    .content{
      padding: 18px;
      bottom: 0;
      background-color: #ffffffe6;
      border-top-left-radius: 16px;
      border-top-right-radius: 16px;
      &>div{
        max-width: 400px;
      }
      .title-cont{
        .title{
          margin: 0 0 12px 0;
        }
        .country{
          top: 20px;
        }
      }
      .desc{
        line-height: 22px;
      }
      button{
        padding: 8px 20px;
      }
    }
  }

  body[dir="ltr"] {
    .workshop-card-cont{
      .content{
        .title-cont{
          .country{
            right: 20px;
          }
        }
      }
    }
  }

  body[dir="rtl"] {
    .workshop-card-cont{
      .content{
        .title-cont{
          .country{
            left: 20px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px){
    .workshop-card-cont{
      .content{
        padding: 10px;
        flex-direction: column;
        align-items: center;
        .title-cont{
          .title{
            margin: 0 0 8px 0;
            font-size: 18px;
          }
        }
        .desc{
          font-size: 12px;
          line-height: 20px;
        }
        button{
          font-size: 14px;
          padding: 6px 12px;
          margin-top: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .workshop-card-cont{
      .content{
        .title-cont{
          flex-direction: column;
          .country{
            position: relative;
            top: unset;
            left: unset!important;
            right: unset!important;
            margin-bottom: 10px;
          }
        }
      }
    }
  }
</style>