<template>
  <div class="loader-cont w-100 h-100 bg-neutral-black d-flex align-center transition-300 absolute">
    <div class="inner-cont vertical layout">
      <img src="../assets/images/logo.jpg" alt="Logo" class="d-block center">
      <p class="ft-size-30 ft-neutral-white ft-w-bold text-center">{{$t("loader.title")}}</p>
      <i class="fas fa-spinner ft-size-50 ft-neutral-white ft-w-bold text-center width-fit center"></i>
    </div>
  </div>
</template>

<script>
  export default {
    name: "loader",
    mounted() {

    },
    data() {
      return {

      }
    },
    methods: {
      loaded: function() {
        this.$el.style.opacity = 0;
        setTimeout(() => {
          this.$el.style.display = "none";
        },300);
      }
    }
  };
</script>

<style scoped lang="scss">
  .loader-cont{
    z-index: 99999999;
    min-height: 100vh;
    padding: 24px;
    top: 0;
    left: 0;
    .inner-cont{
      margin: 0 auto;
      img{
        width: 400px;
      }
      i{
        animation-name: spin;
        animation-duration: 2000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear; 
      }
    }
    @keyframes spin {
      from{
        transform:rotate(0deg);
      }
      to{
        transform:rotate(360deg);
      }
    }
  }

  body[dir="ltr"] {
    .loader-cont{
      .inner-cont{
        p{
          letter-spacing: 12px;
        }
      }
    }
  }

  body[dir="rtl"] {
    .loader-cont{
      .inner-cont{
        p{
          letter-spacing: unset;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .loader-cont{
      .inner-cont{
        img{
          width: 250px;
        }
        p{
          font-size: 20px;
          line-height: 26px;
        }
        i{
          font-size: 36px;
        }
      }
    }
  }
</style>