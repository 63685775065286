<template>
  <div class="review-card-cont w-100 bg-neutral-white shadow border-radius-2 horizontal layout">
    <img v-bind:src="review.pp" alt="profile picture" class="border-radius-full shadow">
    <div class="flex relative">
      <p class="reviewer ft-size-20 ft-grey ft-w-bold">{{review.reviewer}}</p>
      <p class="date ft-size-14 ft-secondary m-0">{{review.date}}</p>
      <p class="review-text ft-size-16 ft-grey">{{review.review}}</p>
      <div class="progress-cont absolute">
        <el-progress type="dashboard" v-bind:percentage="percentage" color="#67c23a" v-bind:format="format"></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import {Progress} from "element-ui";
  
  Vue.component("el-progress",Progress);

  export default {
    name: "review-card",
    components: {
      Progress
    },
    mounted() {
      
    },
    computed: {
      percentage: function() {
        let percentage = 0;
        percentage = (parseFloat(this.review.rate) / 5) * 100;
        
        return percentage;
      }
    },
    props: {
      review: null
    },
    data() {
      return {
        
      }
    },
    methods: {
      format: function() {
        return this.review.rate;
      }
    }
  };
</script>

<style scoped lang="scss">
  .review-card-cont{
    padding: 20px 30px;
    margin-bottom: 24px;
    img{
      width: 150px;
      height: 150px;
      object-fit: cover;
    }
    &>div{
      padding: 0 18px;
      .reviewer{
        margin-bottom: 4px;
      }
      .date{
        font-style: italic;
      }
      .review-text{
        line-height: 24px;
      }
      .progress-cont{
        top: 0;
      }
    }
  }

  body[dir="ltr"] {
    .review-card-cont{
      &>div{
        .progress-cont{
          right: 0;
        }
      }
    }
  }

  body[dir="rtl"] {
    .review-card-cont{
      &>div{
        .progress-cont{
          left: 0;
        }
        .date{
          direction: ltr;
          text-align: right;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .review-card-cont{
      flex-direction: column;
      position: relative;
      &>div{
        position: unset;
        .progress-cont{
          top: 20px;
        }
      }
    }

    body[dir="ltr"] {
      .review-card-cont{
        &>div{
          .progress-cont{
            right: 20px;
          }
        }
      }
    }

    body[dir="rtl"] {
      .review-card-cont{
        &>div{
          .progress-cont{
            left: 20px;
          }
        }
      }
    }
  }
</style>