<template>
  <div class="trainer-card-cont shadow border-radius-4">
    <div class="bio-cont">
      <div class="horizontal layout">
        <img v-bind:src="trainer.pp" v-bind:alt="trainer.name" class="border-radius-full">
        <div class="vertical layout">
          <p class="name ft-size-18 ft-grey ft-w-bold">{{trainer.name}} <a v-bind:href="trainer.linkedin" target="_blank"><i class="fab fa-linkedin"></i></a></p>
          <p class="ft-size-16 ft-grey m-0">{{trainer.title}}</p>
          <p class="country ft-size-16 ft-primary">{{trainer.country}}</p>
        </div>
      </div>
    </div>
    <hr/>
    <div class="desc-cont">
      <p class="ft-size-16 ft-grey m-0">{{trainer.bio}}</p>
    </div>
  </div>
</template>

<script>
  export default {
    name: "trainer-card",
    mounted() {

    },
    props: {
      trainer: {}
    },
    data() {
      return {

      }
    },
    methods: {

    }
  };  
</script>

<style scoped lang="scss">
  .trainer-card-cont{
    padding: 18px 12px;
    margin: 0 16px 24px 16px;
    max-width: 300px;
    .bio-cont{
      img{
        width: 125px;
        height: 125px;
      }
      p{
        line-height: 1.25;
        &.name{
          margin: 0 0 8px 0;
          i{
            color: #2e66c2;
          }
        }
        &.country{
          margin: 8px 0 0 0;
        }
      }
    }
    hr{
      margin: 12px 0;
      border: 0;
      border-top: 1px solid #dcdcdc;
    }
    .desc-cont{
      p{
        line-height: 22px;
      }
    }
  }

  body[dir="ltr"] {
    .trainer-card-cont{
      .bio-cont{
        img{
          margin-right: 16px;
        }
      }
    }
  }

  body[dir="rtl"] {
    .trainer-card-cont{
      .bio-cont{
        img{
          margin-left: 16px;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .trainer-card-cont{

    }
  }
</style>