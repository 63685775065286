<template>
  <div class="freelancers-network-cont">
    <div class="hero-cont vertical layout align-center justify-center relative">
      <img src="../../assets/images/freelancers-network.png" alt="freelancers network" class="w-100 h-100 absolute rellax" data-rellax-speed="-3">
      <div class="relative w-100">
        <p class="title ft-size-70 ft-neutral-white ft-w-bold text-center center">{{$t("business-incubators.freelancers-network")}}</p>
      </div>
    </div>
    <div class="wrapper">
      <div class="freelancers-cont horizontal layout wrap justify-center">
        <freelancer-card v-for="(freelancer,index) in freelancers" v-bind:key="index" v-bind:freelancer="freelancer"></freelancer-card>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import Rellax from "rellax";
  import FreelancerCard from "../../components/businessincubators/FreelancerCard.vue";

  Vue.component("freelancer-card",FreelancerCard);

  export default {
    name: "freelancers-network",
    components: {
      FreelancerCard
    },
    mounted() {
      new Rellax('.rellax');
    },
    data() {
      return {
        freelancers: [
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            hourly_rate: "30",
            tags: [
              "Web design","HTML","CSS","Javascript","vuejs"
            ],
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan",
            hourly_rate: "30",
            tags: [
              "Web design","HTML","CSS","Javascript","vuejs"
            ],
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan",
            hourly_rate: "30",
            tags: [
              "Web design","HTML","CSS","Javascript","vuejs"
            ],
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            hourly_rate: "30",
            tags: [
              "Web design","HTML","CSS","Javascript","vuejs"
            ],
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan",
            hourly_rate: "30",
            tags: [
              "Web design","HTML","CSS","Javascript","vuejs"
            ],
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan",
            hourly_rate: "30",
            tags: [
              "Web design","HTML","CSS","Javascript","vuejs"
            ],
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan" ,
            hourly_rate: "30",
            tags: [
              "Web design","HTML","CSS","Javascript","vuejs"
            ],
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan",
            hourly_rate: "30",
            tags: [
              "Web design","HTML","CSS","Javascript","vuejs"
            ],
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          },
          {
            name: "Abdullah Abu Sham",
            title: "Senior Frontend Engineer",
            pp: "https://avatars.githubusercontent.com/u/11394132?v=4",
            country: "Jordan",
            hourly_rate: "30",
            tags: [
              "Web design","HTML","CSS","Javascript","vuejs"
            ],
            linkedin: "https://www.linkedin.com/in/abdullahabusham"
          }
        ]
      }
    },
    methods: {
      
    }
  };
</script>

<style scoped lang="scss">
  .freelancers-network-cont{
    .hero-cont{
      height: 400px;
      overflow: hidden;
      background-color: #d8d8d8;
      img{
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
        opacity: 0.7;
      }
      .title{
        text-shadow: 0 0px 8px rgba(0,0,0,0.5);
        max-width: 750px;
      }
    }
    .wrapper{
      padding: 50px 0;
    }
  }

  @media only screen and (max-width: 768px){
    .freelancers-network-cont{
      .hero-cont{
        height: 400px;
        .title{
          font-size: 40px;
          max-width: 90%;
          margin-top: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .freelancers-network-cont{
   
    }
  }
</style>