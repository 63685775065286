<template>
  <div class="coworking-spaces-cont">
    <div class="hero-cont bg-neutral-black d-flex align-center justify-center relative">
      <img src="../assets/images/services/coworking.jpg" alt="coworking spaces" class="w-100 h-100 absolute rellax" data-rellax-speed="-3">
      <div class="relative">
        <p class="title ft-size-70 ft-neutral-white ft-w-bold text-center center">{{$t("coworking.hero.title")}}</p>
        <div class="specs-cont horizontal layout space-between">
          <div class="width-fit d-flex vertical layout justify-center align-center">
            <i class="fas fa-laugh ft-size-40 ft-neutral-white"></i>
            <p class="spec-title ft-size-18 ft-neutral-white ft-w-bold text-center">{{$t("coworking.hero.spec1.title")}}</p>
            <p class="ft-size-14 ft-neutral-white ft-w-bold text-center m-0">{{$t("coworking.hero.spec1.subtitle")}}</p>
          </div>
          <div class="width-fit d-flex vertical layout justify-center align-center">
            <i class="fas fa-user-check ft-size-40 ft-neutral-white"></i>
            <p class="spec-title ft-size-18 ft-neutral-white ft-w-bold text-center">{{$t("coworking.hero.spec2.title")}}</p>
            <p class="ft-size-14 ft-neutral-white ft-w-bold text-center m-0">{{$t("coworking.hero.spec2.subtitle")}}</p>
          </div>
          <div class="width-fit d-flex vertical layout justify-center align-center">
            <i class="fas fa-chair ft-size-40 ft-neutral-white"></i>
            <p class="spec-title ft-size-18 ft-neutral-white ft-w-bold text-center">{{$t("coworking.hero.spec3.title")}}</p>
            <p class="ft-size-14 ft-neutral-white ft-w-bold text-center m-0">{{$t("coworking.hero.spec3.subtitle")}}</p>
          </div>
        </div>
        <button class="primary ft-size-26 ft-w-bold border-radius-32 d-block shadow" v-on:click="scrollToSpaces">{{$t("coworking.hero.cta")}} <i class="fas fa-arrow-down bounce relative"></i></button>
      </div>
    </div>
    <div class="spaces-cont">
      <div class="wrapper">
        <div class="spaces-inner-cont">
          <space-card v-for="(space,index) in spaces" v-bind:key="index" v-bind:space="space"></space-card>
        </div>
        <button class="primary ft-size-20 ft-w-bold border-radius-32 d-block shadow">{{$t("shared.load-more")}}</button>
      </div>
    </div>
    <div class="our-partners-cont bg-light-grey">
      <div class="wrapper">
        <p class="ft-size-30 ft-grey ft-w-bold text-center width-fit relative">{{$t("coworking.our-partners")}}</p>
        <div class="partners-cont horizontal layout wrap space-evenly">
          <a href="https://coffeeprojectny.com" target="_blank"><img src="../assets/images/partners/coffee-project-ny.png" alt="coffee-project-ny"></a>
          <a href="https://www.cafebarbera.com" target="_blank"><img src="../assets/images/partners/barbera.png" alt="barbera"></a>
          <a href="https://sevenpenniescoffee.com" target="_blank"><img src="../assets/images/partners/7-pennies.jpeg" alt="7 pennies"></a>
          <a href="https://carusoscoffee.com" target="_blank"><img src="../assets/images/partners/carusos.png" alt="carusos"></a>
          <a href="https://cafe-henrici.ch" target="_blank"><img src="../assets/images/partners/henrici.png" alt="henrici"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from "vue";
  import SpaceCard from "../components/coworking/SpaceCard.vue"; 
  import Rellax from "rellax";

  Vue.component("space-card",SpaceCard);

  export default {
    name: "coworking-spaces",
    components: {
      SpaceCard
    },
    mounted() {
      new Rellax('.rellax');
    },
    computed: {
      spaces: function() {
        return this.DBspaces;
      }
    },
    data() {
      return {
        
      }
    },
    methods: {
      scrollToSpaces: function() {
        let target = this.$el.querySelector(".spaces-cont");
        let scrollToPosition = target.getBoundingClientRect().top + window.pageYOffset - 80;

        window.scrollTo({
          top: scrollToPosition, 
          behavior: "smooth"
        });
      }
    }
  };  
</script>

<style scoped lang="scss">
  .coworking-spaces-cont{
    .hero-cont{
      height: 600px;
      overflow: hidden;
      img{
        top: 0;
        left: 0;
        object-fit: cover;
        object-position: center center;
        opacity: 0.7;
      }
      .title{
        text-shadow: 0 0px 8px rgba(0,0,0,0.5);
        max-width: 750px;
      }
      .specs-cont{
        margin-top: 80px;
        .spec-title{
          margin: 18px auto 4px auto;
        }
        p,i{
          text-shadow: 0 0px 8px rgba(0,0,0,0.5);
        }
      }
      button{
        padding: 12px 32px;
        margin: 46px auto 0 auto;
        .bounce {
          animation: bounce 2s infinite;
        }

        @keyframes bounce {
          0%, 20%, 50%, 80%, 100% {
            transform: translateY(0);
          }
          40% {
            transform: translateY(-10px);
          }
          60% {
            transform: translateY(-5px);
          }
        }
      }
    }
    .spaces-cont{
      padding: 50px 0;
      .wrapper{
        button{
          padding: 10px 70px;
          margin: 50px auto 24px auto;
        }
      }
    }
    .our-partners-cont{
      padding: 50px 0;
      .wrapper{
        p{
          margin: 0 auto 50px auto;
          &::after,&::before{
            position: absolute;
            width: 180px;
            height: 3px;
            bottom: -12px;
            background-color: var(--primary);
            content: '';
            border-left: solid 5px #f1f1f1;
            border-right: solid 5px #f1f1f1;
          }
          &::before{
            border: none;
            width: 200px; 
          }
        }
        .partners-cont{
          a{
            margin: 24px;
            img{
              width: 100%;
              max-height: 100px;
              max-width: 200px;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
  }

  body[dir="ltr"] {
    .coworking-spaces-cont{
      .hero-cont{
        button{
          i{
            margin-left: 8px;
          }
        }
      }
      .our-partners-cont{
        .wrapper{
          p{
            &::after,&::before{
              left: 12px;
            }
            &::before{
              left: 7px;
            }
          }
        }
      }
    }
  }

  body[dir="rtl"] {
    .coworking-spaces-cont{
      .hero-cont{
        button{
          i{
            margin-right: 8px;
          }
        }
      }
      .our-partners-cont{
        .wrapper{
          p{
            &::after,&::before{
              right: -45px;
            }
            &::before{
              right: -50px;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 768px){
    .coworking-spaces-cont{
      .hero-cont{
        height: unset;
        .title{
          font-size: 40px;
          max-width: 90%;
          margin-top: 40px;
        }
        .specs-cont{
          margin-top: 45px;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          &>div{
            padding: 0 10px;
            margin: 0 0 20px 0;
            i{
              font-size: 30px;
            }
            .spec-title{
              font-size: 16px;
              margin: 10px auto 4px auto;
            }
          }
        }
        button{
          font-size: 18px;
          margin: 12px auto 28px auto;
        }
      }
    }
  }

  @media only screen and (max-width: 575px){
    .coworking-spaces-cont{
      .spaces-cont{
        padding: 12px 0;
        .wrapper{
          button{
            font-size: 18px;
          }
        }
      }
      .our-partners-cont{
        padding: 32px 0;
        .wrapper{
          p{
            font-size: 22px;
            margin: 0 auto 40px auto;
          }
          .partners-cont{
            a{
              margin: 12px;
              img{
                max-height: 50px;
                max-width: 100px;
              }
            }
          }
        }
      }
    }

    body[dir="ltr"] {
      .coworking-spaces-cont{
        .our-partners-cont{
          .wrapper{
            p{
              &::after,&::before{
                left: -17px;
              }
              &::before{
                left: -22px;
              }
            }
          }
        }
      }
    }

    body[dir="rtl"] {
      .coworking-spaces-cont{
        .our-partners-cont{
          .wrapper{
            p{
              &::after,&::before{
                right: -60px;
              }
              &::before{
                right: -65px;
              }
            }
          }
        }
      }
    }
  }
</style>