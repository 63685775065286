import Vue from "vue";
import VueRouter from "vue-router";

/**********  GLOBAL VIEWS **********/
import Home from "../views/Home.vue";
import CoworkingSpaces from "../views/CoworkingSpaces.vue";
import BusinessIncubators from "../views/BusinessIncubators.vue";
import TrainingsMentorship from "../views/TrainingsMentorship.vue";

/**********  COWORKING VIEWS **********/
import SpacePage from "../views/Coworking/SpacePage.vue";

/*****  BUSINESS INCUBATORS VIEWS *****/
import ServicePage from "../views/BusinessIncubators/ServicePage.vue";
import WorkshopPage from "../views/BusinessIncubators/WorkshopPage.vue";
import FreelancersNetwork from "../views/BusinessIncubators/FreelancersNetwork.vue";

/***  TRAININGS & MENTORSHIP VIEWS ****/
import TrainingPage from "../views/TrainingsMentorship/TrainingPage.vue";
import TrainersNetwork from "../views/TrainingsMentorship/TrainersNetwork.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      pageTitle: "READY TO RISE & GRIND?"
    }
  },
  {
    path: "/coworking-spaces",
    name: "coworking-spaces",
    component: CoworkingSpaces,
    meta: {
      pageTitle: "Coworking Spaces"
    }
  },
  {
    path: "/coworking-spaces/:space",
    name: "space page",
    component: SpacePage,
    meta: {
      pageTitle: "Coworking Spaces"
    }
  },
  {
    path: "/business-incubators",
    name: "business-incubators",
    component: BusinessIncubators,
    meta: {
      pageTitle: "Business Incubators"
    }
  },
  {
    path: "/business-incubators/services/:service",
    name: "service page",
    component: ServicePage,
    meta: {
      pageTitle: "Business Incubators"
    }
  },
  {
    path: "/workshops-events/:workshop",
    name: "workshop page",
    component: WorkshopPage,
    meta: {
      pageTitle: "Business Incubators"
    }
  },
  {
    path: "/business-incubators/freelancers-network",
    name: "freelancers network",
    component: FreelancersNetwork,
    meta: {
      pageTitle: "Freelancers Network"
    }
  },
  {
    path: "/trainings-and-mentorship",
    name: "trainings-and-mentorship",
    component: TrainingsMentorship,
    meta: {
      pageTitle: "Trainings and Mentorship"
    }
  },
  {
    path: "/trainings-and-mentorship/trainings/:training",
    name: "training page",
    component: TrainingPage,
    meta: {
      pageTitle: "Trainings and Mentorship"
    }
  },
  {
    path: "/trainings-and-mentorship/trainers-network",
    name: "trainers network",
    component: TrainersNetwork,
    meta: {
      pageTitle: "Trainers Network"
    }
  },
  {
    path: "/*",
    name: "404",
    beforeEnter: (to, from, next) => {
      next({path:"/home"});
      next();
    }
  }
]

const router = new VueRouter({
  mode: "history",
  routes
});

export default router;
